import React, { useState, useEffect, useContext } from "react";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { downloadFileImage } from "../../apollo/mutations";
import { GlobalTheme } from '../../context/GlobalTheme';
import './five-position.css';
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from 'react-redux';

const FivePositionLayoutPanel = ({ panelJSON, cardsLayoutQuery, screenList }) => {
    const { card_definition, controls, query_name } = panelJSON;
    const dispatch = useDispatch();
    const globalTheme = useContext(GlobalTheme);
    const [fetchedData, setFetchedData] = useState([]);
    const [staticDownloadImageContent, setStaticDownloadedImages] = useState({});
    const [imageContent, setImageContent] = useState(null);
    const [mimeType, setMimeType] = useState(null);
    const client = useApolloClient();
    const navigate = useNavigate();

    const [downloadImage] = useMutation(downloadFileImage);

    const fetchData = async () => {
        if (!cardsLayoutQuery) {
            dispatch({ type: 'SAVE_REQUIRED_STATUS_SCREEN', data: true });
            return []
        }
        try {
            const { data } = await client.query({
                query: gql`${cardsLayoutQuery}`,
                variables: { limit: 10, offset: 0 },
            });
            return data?.[query_name] || [];
        } catch (error) {
            console.error("Error fetching data:", error);
            return [];
        }
    };

    const determineMimeType = (base64String) => {
        if (!base64String) return null;
        if (base64String.startsWith('/9j/')) return 'image/jpeg'; // JPEG or JPG
        if (base64String.startsWith('iVBORw')) return 'image/png'; // PNG
        if (base64String.startsWith('PD94bWwg') || base64String.startsWith('PHN2Zy')) return 'image/svg+xml'; // SVG
        return null; // Unknown or unsupported type
    };

    const downloadImageContent = async (imageId) => {
        try {
            const { data } = await downloadImage({ variables: { files_uid: imageId } });
            if (data?.fileDownload?.file_content) {
                const fileContent = data.fileDownload.file_content;
                setImageContent(fileContent); // Save the base64 content of the image in state
                const mime = determineMimeType(fileContent);
                setMimeType(mime);
                return fileContent; // Return the content for further usage if needed
            }
            return null;
        } catch (error) {
            console.error("Error downloading image:", error);
            return null;
        }
    };

    useEffect(() => {
        positions.forEach((position) => {
            if (
                position.sys_panels_cards_positions_source_types_guid === "f0f1aeb1-5919-4a3d-a5c1-c564ec07cecf" &&
                position.files_guid &&
                !staticDownloadImageContent[position.files_guid] // prevent re-fetching
            ) {
                downloadImageContent(position.files_guid).then((content) => {
                    if (content) {
                        setStaticDownloadedImages((prev) => ({
                            ...prev,
                            [position.files_guid]: content,
                        }));
                    }
                });
            }
        });
    }, [positions, staticDownloadImageContent]);

    const getControlByGuid = (guid) => {
        return controls.find((control) => control.sys_columns_guid === guid);
    };

    const getBackgroundStyle = (backgroundGuid) => {
        const mainColor = globalTheme[globalTheme.activeTheme]?.headerBackground || "#FFF"; // Default to orange

        const shadeColor = (color, percent) => {
            // Parse the color components
            let R = parseInt(color.substring(1, 3), 16);
            let G = parseInt(color.substring(3, 5), 16);
            let B = parseInt(color.substring(5, 7), 16);

            // Apply the percentage change
            R = Math.min(255, Math.max(0, Math.floor(R + (R * percent) / 100)));
            G = Math.min(255, Math.max(0, Math.floor(G + (G * percent) / 100)));
            B = Math.min(255, Math.max(0, Math.floor(B + (B * percent) / 100)));

            // Convert back to hexadecimal, ensuring 2-digit format
            const toHex = (value) => value.toString(16).padStart(2, '0');
            return `#${toHex(R)}${toHex(G)}${toHex(B)}`;
        };

        switch (backgroundGuid) {
            case "b4f6c388-cc1a-43da-9659-4c339c877201": // None
                return {};
            case "0ff58f23-f20b-413b-afa5-73d0c6f6a465": // Solid
                return { backgroundColor: mainColor };
            case "c2f9d965-407e-4161-9321-a5d13ad0f6c5": // Gradient
                const darkerColor = shadeColor(mainColor, -20); // Darker at the top
                const lighterColor = shadeColor(mainColor, 40);  // Lighter at the bottom
                return {
                    background: `linear-gradient(to bottom, ${darkerColor}, ${lighterColor})`,
                    width: '100%',
                    height: '100%',
                    borderRadius: '8px', // Optional for rounded corners
                    border: '1px solid #000',
                    overflow: 'hidden',  // Prevents children from overflowing the container
                };
            case "989433eb-882f-4acf-bc84-1250be61a3a6": // Image
                return {
                    // backgroundImage: `url(/path-to-image)`, // Replace with actual image logic if necessary
                    // backgroundSize: "cover",
                    // backgroundPosition: "center",
                };
            default:
                return {};
        }
    };

    const getPositionContent = (position) => {
        const { sys_columns_guid, sys_panels_cards_positions_source_types_guid, files_guid } = position;
        const control = getControlByGuid(sys_columns_guid);

        switch (sys_panels_cards_positions_source_types_guid) {
            case "d40d90de-2eda-4b5f-8ff5-ea949211f578":
                return control.label;

            case "308d8765-039f-4516-870d-4dccbef4bda5":
                const fieldName = control.name;
                const fieldValue = fetchedData[0]?.[fieldName];
                if (control.type === "image" && fieldValue) {
                    return <img src={`data:${mimeType};base64,${imageContent}`} alt="Downloaded" style={{
                        width: "100%",
                        height: "100%",
                    }} />;
                }
                if (control.type === "number" && fieldValue) {
                    // Format the number with thousand separators
                    const formattedNumber = new Intl.NumberFormat('en-US').format(fieldValue);
                    return formattedNumber;
                }
                return fieldValue || null;

            case "f0f1aeb1-5919-4a3d-a5c1-c564ec07cecf":
                if (files_guid) {
                    const imageContent = staticDownloadImageContent[files_guid];
                    // If the image hasn't downloaded yet, you can render a loading indicator or placeholder.
                    if (!imageContent) {
                        return "Loading image...";
                    }
                    return (
                        <img src={`data:${mimeType};base64,${imageContent}`} alt="Downloaded" style={{
                            width: "100%",
                            height: "100%",
                        }} />
                    );
                }
                return null;

            default:
                return "Unknown Type";
        }
    };

    const goToAddPage = (event) => {
        if (event.action_parameters === null) {
            navigate(`/${event.action_value}`);
        }
        else {
            event.action_parameters.map((param) => {
                if (param.param_value.startsWith("@v_")) {
                    const matchingScreen = screenList.find((screen) => screen.param === param.param_value.replace('@', ''));
                    param.param_value = matchingScreen?.param_value || null
                } else if (param.param_value.startsWith("@")) {
                    const selectedId = param.param_value.replace(/@/g, '');
                    param.param_value = fetchedData?.[0]?.[selectedId];
                }
            });
            navigate(`/${event.action_value}`, { state: { param_value: event } });
        }
    };

    const handleOnRowEvent = () => {
        if (panelJSON.events?.length > 0) {
            const event = panelJSON.events[0];
            if (event.sys_actions_types_guid === "f61dd1fe-da30-4955-bc6d-1e0be70ff257") {
                const copyOfEvent = JSON.parse(JSON.stringify(event));
                goToAddPage(copyOfEvent)
            }
        }
    };

    useEffect(() => {
        const loadData = async () => {
            const data = await fetchData();
            setFetchedData(data);

            // Automatically download the image if "image" field exists in the first item
            const firstItemImageId = data[0]?.image;
            if (firstItemImageId) {
                await downloadImageContent(firstItemImageId);
            }
        };

        loadData();
    }, [cardsLayoutQuery]);

    const positions = card_definition[0]?.positions || [];
    const backgroundStyle = getBackgroundStyle(
        card_definition[0]?.sys_panels_views_cards_background_types_guid
    );

    return (
        <div
            className="custom-five-position-layout"
            onClick={handleOnRowEvent}
            style={{ ...backgroundStyle, cursor: 'pointer' }}
        >
            {positions.map((position) => (
                <div key={position.position} className={`position-${position.position}`}>
                    {getPositionContent(position)}
                </div>
            ))}
        </div>
    );
};

export default FivePositionLayoutPanel;
