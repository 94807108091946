import React, { useRef } from 'react';
import {FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Checkbox} from '@mui/material';
import {Form} from '@patternfly/react-core';
import {useSelector} from "react-redux";
import {TextBoxComponent} from "@syncfusion/ej2-react-inputs";
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";

const CreateRegionForm = (props) => {
    const regionsTypes = useSelector((state) => state.mainReducer.regionsTypes);

    const updateName = (value) => props.setFormData((prev) => ({...prev, regions_name: value}));
    const updateType = (value) => props.setFormData((prev) => ({...prev, regions_types_guid: value}));
    const ref = useRef(null)

    return (
        <Form style={{width: "100%", padding: "10px 15px"}}>

            <section className='dynamic-forms'>
                <label style={{fontSize: '12px', color: '#757575'}}>Region Name</label>
                {/*<TextBoxComponent*/}
                {/*    change={({ value }) => updateName(value)}*/}
                {/*    value={props.formData.regions_name}*/}
                {/*/>*/}
                <input className="input-manage-regions" ref={ref} onClick={() => {
                    ref.current?.focus()
                }} onChange={event => updateName(event.target.value)} value={props.formData.regions_name} type="text"/>
            </section>

            <section className='dynamic-forms'>
                <label style={{fontSize: '12px', color: '#757575'}}>Region type</label>
                <DropDownListComponent
                    dataSource={regionsTypes}
                    fields={{value: 'regions_types_guid', text: 'region_type_name'}}
                    select={({ itemData }) => {
                        updateType(itemData.regions_types_guid);
                    }}
                    value={props.formData.regions_types_guid}
                />
            </section>

        </Form>
    );
}

export default CreateRegionForm;