import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import { ContextMenuComponent } from "@syncfusion/ej2-react-navigations";

import './drawer.css';
import { SENSORS } from "../../../utils/data";
import { SidebarContext } from '../../../pages/dynamicScreens/Main/context';

const SynDrawer = ({ setExpanded, isExpanded, regionsPickerShow, setOpenedPanel, setShowMarkers, showMarkers, showMarkersOnPanel }) => {
  const showPointMode = useSelector((state) => state.mainReducer.showPointMode); // 1-all points, 2-only final point
  const showAnimationDotMode = useSelector((state) => state.mainReducer.animatedPointsMode);
  const sensorsVisible = useSelector((state) => state.mainReducer.visibleSensorTypes);
  const showRegionsPicker = useSelector((state) => state.mainReducer.showRegionsPicker);

  const [isOpen, setOpen] = useState();
  const dispatch = useDispatch();

  let sidebarRef = useRef();
  useEffect(() => setOpen(isExpanded), [isExpanded]);

  useEffect(() => {
    if (isOpen === true) sidebarRef.current.show();
  }, [isOpen]);

  let editShowPointMode = (event) => {
    dispatch({ type: 'EDIT_SHOW_POINT_MODE', data: event });
  }

  let editAnimationMode = (event) => {
    dispatch({ type: 'EDIT_MODE', data: event });
  }

  let editRegionsPickerVisibility = () => {
    dispatch({ type: 'EDIT_REGIONS_PICKER_VISIBILITY' });
  }

  let selectVisibleSensorType = (event) => {
    const label = event.target.id;
    dispatch({ type: 'EDIT_SENSOR_TYPE_VISIBILITY', data: { ...sensorsVisible, [label]: !sensorsVisible[label] } });
  }

  const onCloseClick = () => {
    if (setOpenedPanel) {
      setOpenedPanel(prevState => ({
        ...prevState,
        settings: false
      }))
    }
    if (setExpanded) {
      setExpanded(!isExpanded);
    }
  }

  let onmouseover = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  const editShowMarkers = (event) => {
    setShowMarkers(event)
  }

  return (
    <SidebarComponent ref={sidebarRef} animate position="Right" isOpen={isExpanded} type="Over" width="200px" >
      <div onMouseDown={onmouseover} className="drawer-wrapper">
        <div className="sidebar-header">
          <span className="sidebar-settings-title">Settings</span>
          <span id="close" className="e-icons e-close-large-1" onClick={onCloseClick}></span>
        </div>

        <div className="sidebar-content">

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={showAnimationDotMode}
          >
            <FormControlLabel value={1} control={<Radio />} label="Default dot mode" onClick={() => editAnimationMode(1)} />
            <FormControlLabel value={2} control={<Radio />} label="Smoothing dot mode" onClick={() => editAnimationMode(2)} />
          </RadioGroup>

          <hr />

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={1}
            value={showPointMode}
          >
            <FormControlLabel value={1} control={<Radio />} label="Show all dots" onClick={() => editShowPointMode(1)} />
            {showPointMode === 1 && (
              <div style={{ paddingLeft: '30px' }}>
                {Object.keys(sensorsVisible).map(el => (
                  <FormControlLabel
                    key={el}
                    control={(
                      <Checkbox id={el}
                        checked={!!sensorsVisible[el]}
                        onChange={selectVisibleSensorType}
                      />
                    )}
                    label={SENSORS[el].title}
                    style={{ color: SENSORS[el].color }}
                  />
                ))}
              </div>
            )}
            <FormControlLabel value={2} control={<Radio />} label="Show only final dot" onClick={() => editShowPointMode(2)} />
          </RadioGroup>

          <hr />

          {showMarkersOnPanel && (
              <>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={showAnimationDotMode}
                >
                  <FormControlLabel checked={showMarkers} value={showMarkers} control={<Radio/>} label="Show markers"
                                    onClick={() => editShowMarkers(true)}/>
                  <FormControlLabel checked={!showMarkers} value={showMarkers} control={<Radio/>} label="Hide markers"
                                    onClick={() => editShowMarkers(false)}/>
                </RadioGroup>

                <hr/>
              </>
          )}

          {regionsPickerShow &&
            <button className='regions-show-btn' onClick={editRegionsPickerVisibility}>{showRegionsPicker ? 'Show Regions Picker' : 'Hide Regions Picker'}</button>
          }

        </div>
      </div>
    </SidebarComponent>
  )
}

export default SynDrawer;
