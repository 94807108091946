import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    GridComponent as GridComponentEJ2,
    ColumnsDirective,
    ColumnDirective,
    Inject,
    Filter,
    Sort
} from '@syncfusion/ej2-react-grids'
import { MenuComponent } from '@syncfusion/ej2-react-navigations'
import { Tooltip } from '@syncfusion/ej2-react-popups'
import { useApolloClient } from '@apollo/react-hooks'
import { GET_SYS_USERS_LIST } from '../../apollo/queries'
import { GlobalTheme } from '../../context/GlobalTheme'
import styled from 'styled-components'
import { ToastComponent } from '@syncfusion/ej2-react-notifications'
import { useNavigate } from 'react-router';
import { SelectedRowContext } from '../../context/SelectedRowContext';
import SynModal from '../../mapping/components/modals/modal';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import SetPasswordModal  from './SetPasswordModal'
import axios from 'axios'

const Wrapper = styled('div')`
  & #Grid_content_table {
    background: ${props => props.bg};
  }
  
  & td {
    color: ${props => props.textColor} !important;
  }
`

const GridComponent = styled(GridComponentEJ2)`
  & .e-content {
    background: ${props => props.bg} !important;
  }
`

export const UsersList = () => {
    const toastRef = useRef(null);
    const navigate = useNavigate();
    const client = useApolloClient();
    const globalTheme = useContext(GlobalTheme)
    const [gridList, setGridList] = useState('')
    const [selectedUser, setSelectedUser] = useState('')
    const [error, setError] = useState(false)
    const [pass, setPass] = useState('')
    const [modalIsOpenPassword, setModalIsOpenPassword] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const { selectedRow } = useContext(SelectedRowContext)
    const user = JSON.parse(localStorage.getItem('user'))
    const selectedTenant = JSON.parse(localStorage.getItem('selectedTenant'))
    const menuItems = [
        {
            text: 'Add New User',
            iconCss: `e-icons e-save`,
            cssClass: 'menu-component-button',
        },
        {
            text: 'Set Password',
            iconCss: `e-icons e-password`,
            cssClass: 'menu-component-button',
        }

    ]

    const setPasswordHandleClick = async () => {
        try {
            const d = await axios.post(`https://authgw-syn-baseplatform-uat.dev.syncrasy.cloud/superadmin/user/password`, {
                username: selectedUser.user_name,
                password: pass,
                locale: 'en',
                key: '467w2435256'
            }, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`
                }
            })
            if (d.status === 200) {
                showToast('Password has been updated', 'success')
                setModalIsOpenPassword(false)
                _getUsersList();
                
            }
        }

        catch (e) {
            showToast(`An error occurred: ${e.message} ${e.response ? `(${e.response.data.error})` : null}`, 'danger')
            console.error('An error occurred:', e);
        }

    }

    const content = (
        <>
            <SetPasswordModal
                pass={pass}
                error={error}
                setPass={setPass}
                userNameEmail={selectedUser}
                setModalIsOpen={setModalIsOpenPassword}
            />
        </>
    )

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    function rowSelected(g) {
        setSelectedUser(g.data)
    }


    const getActionButton = (args) => {
        switch (true) {
            case args.item.properties.text.toLowerCase().includes('add'): {
                return navigate('/add-users')
            }
            case args.item.properties.text.toLowerCase().includes('password'):
                if (selectedUser) {
                    return setModalIsOpenPassword(true)
                }
                else {
                    return showToast('Please select a user row!', 'danger')
                }
            default:
                return null
        }
    }

    const _getUsersList = async () => {
        const { data } = await client.query({ query: GET_SYS_USERS_LIST, variables: { v_tenants_guid: selectedRow?.tenants_guid || selectedTenant } })
        const transformedData = data.dal_sys_users_list_get.map(item => ({
            users_guid: item.users_guid,
            role_name: item.role_name,
            user_name: item.user_name,
            is_deactivated: item.is_deactivated,
        }));
        setGridList(transformedData)
    }

    useEffect(() => {
        _getUsersList()

    }, [selectedRow]);

    return (
        <>
            <ToastComponent ref={toastRef} />
            <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: globalTheme[globalTheme.activeTheme]?.headerBackground, padding: '0 10px', alignItems: 'center', height: '40px', fontSize: '14px', }}>
                Users List
                <div className={`${globalTheme?.activeTheme}`} style={{ height: '40px' }}>
                    <MenuComponent items={menuItems} select={getActionButton}></MenuComponent>
                </div>
            </div>
            <Wrapper
                className='control-section'
                bg={globalTheme[globalTheme.activeTheme]?.background}
                textColor={globalTheme[globalTheme.activeTheme]?.color}
                style={{ height: 'calc(100% - 49px)', width: '100%' }}
            >
                <GridComponent
                    bg={globalTheme[globalTheme.activeTheme]?.background}
                    id="Grid"
                    height={'100%'}
                    dataSource={gridList}
                    allowFiltering={true}
                    allowSorting={true}
                    rowSelected={rowSelected}
                    filterSettings={{
                        type: 'Excel',
                        showFilterBarStatus: true,
                    }}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='users_guid' headerText='users_guid' width='120' ></ColumnDirective>
                        <ColumnDirective field='role_name' headerText='role_name' width='120'></ColumnDirective>
                        <ColumnDirective field='user_name' headerText='user_name' width='120' />
                        <ColumnDirective field='is_deactivated' headerText='is_deactivated' width='120' ></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Tooltip, Filter, Sort]} />
                </GridComponent>

            </Wrapper>
            <SynModal
                error={error}
                content={content}
                openModal={modalIsOpenPassword}
                title={'Set Password'}
                save={setPasswordHandleClick}
                cancel={() => setModalIsOpenPassword(false)}
            />
        </>
    )
}