import React, {useState, useEffect} from "react";
import { useApolloClient } from '@apollo/react-hooks';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
 CheckBoxComponent,
 RadioButtonComponent,
 ButtonComponent,
} from "@syncfusion/ej2-react-buttons";
import {gql} from "@apollo/client";


const Input = ({ value, queryDropdown, onChange, type, data_type, ...rest }) => {
  const client = useApolloClient();
  const [gridList, setGridList] = useState('');
  const [fields, setFields] = useState({value: '', text: '',});

  const _getDynamicScreen = async () => {
    setGridList('')
    setFields({value: '', text: '',})
    let screenQuery = queryDropdown.replace('@v_locale', 'en')
    screenQuery = screenQuery.substring(1)
    const {data} = await client.query({query: gql`${screenQuery}`})
    const queryName = rest.query_name
    setFields({value: rest.query_key, text: rest.query_value})
    setGridList(data[queryName])
  }

  useEffect( () => {
      if(queryDropdown !== null){
        _getDynamicScreen()
      }
      
  }, [])
  


 switch (data_type) {
   case "textbox":
     return (
       <TextBoxComponent
         readOnly={!rest?.editable}
         placeholder={rest?.placeholder}
         change={({ value }) => onChange(value)}
         value={value}
       />
     );
    case "number":
    return (
      <NumericTextBoxComponent
        readOnly={!rest?.editable}
        placeholder={rest?.placeholder}
        change={({ value }) => onChange(value)}
        value={value}
      />
    );
   case "radio":
     return rest?.options.map((e) => (
       <RadioButtonComponent
         readOnly={!rest?.editable}
         key={e}
         label={e}
         value={e}
         onChange={(value) => onChange(value)}
         checked={value === e}
       />
     ));
   case "dropdown":
        
     return (
       <ComboBoxComponent
         readOnly={!rest?.editable}
         dataSource={gridList}
         fields={fields}
         allowFiltering={true}
         filterType='Contains'
         filtering={(e) => {
          if (e.text === '') {
            e.updateData(gridList);
          } else {
            let filteredData = gridList.filter(
              (item) => item[fields.text].toLowerCase().indexOf(e.text.toLowerCase()) !== -1
            );
            e.updateData(filteredData);
          }
         }}
         select={({ itemData }) => {
          onChange(itemData[fields.value]);
        }}
        change={(e) => {
          if(e.itemData === null) {
            onChange(null);
          }
        }}
         value={value || null}
       />
     );

   case "checkbox":
     return (
       <CheckBoxComponent
         readOnly={rest?.editable}
         label={rest?.checkboxLabel}
         onChange={(e) => onChange(e.target.checked)}
         checked={value}
       />
     );

   case "datetime":
     return (
      <DatePickerComponent format='yyyy-MM-dd' id="datepicker" change={({ value }) => onChange(value)} placeholder="Enter date" value={value}/>
     );

   default:
     return null;
 }
};

export default Input;