import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import { Button } from '@mui/material'

import { getRegions, getRegionsGroupsByMapType, getRegionsTypes, getTaskStatus } from '../../apollo/queries'
import { OPENSTREETMAP_TYPE_GUID } from '../../../utils/data'
import { deleteRegionsGroup } from '../../apollo/mutations';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

const RegionGroupsPanel = (setRegionsGroupModalVisibility) => {
    const toastRef = useRef();
    const regionsGroups = useSelector((state) => state.mainReducer.regionsGroups)
    const currentRegionGroup = useSelector((state) => state.mainReducer.currentRegionGroup) //only guid
    const currentMapType = useSelector((state) => state.mainReducer.currentMapType)
    const currentMap = useSelector((state) => state.mainReducer.currentMap)
    const [selectedDeleteRegionId, setSelectedDeleteRegionId] = useState(null)

    const dispatch = useDispatch()
    const client = useApolloClient()
    const locale = localStorage.getItem('locale')

    let onRegionGroupChange = (event) => {
        dispatch({ type: 'SET_CURRENT_REGIONS_GROUP', data: event })
        _getRegions(event)
    }

    const _getRegionsGroups = async () => {
        try {
            const { data, errors } = await client.query({
                query: getRegionsGroupsByMapType,
                variables: {
                    maps_types_guid: currentMapType.maps_types_guid || OPENSTREETMAP_TYPE_GUID,
                    locale: locale ? locale : 'en'
                }
            })
            if (data) dispatch({ type: 'SET_REGIONS_GROUPS', data: data.dal_get_regions_groups_list3 })
            if (errors?.length > 0) {
                throw new Error(errors[0].message)
            }
        } catch (e) {
            console.log(e.message)
        }
    }


    const _getRegionsTypes = async () => {
        try {
            const { data, errors } = await client.query({
                query: getRegionsTypes,
                variables: { locale: locale ? locale : 'en' }
            })
            if (data) dispatch({ type: 'SET_REGIONS_TYPES', data: data.dal_get_regions_types_list })
            if (errors?.length > 0) {
                throw new Error(errors[0].message)
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const _getRegions = async (regions_groups_guid) => {
        // currentRegionGroup
        try {
            const { data, errors } = await client.query({
                query: getRegions,
                variables: { regions_groups_guid, regions_types_guid: null, locale: locale ? locale : 'en' }
            })

            if (data) dispatch({ type: 'SET_REGIONS', data: data.dal_get_regions_list2 })
            if (errors?.length > 0) {
                throw new Error(errors[0].message)
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        _getRegionsGroups()
        _getRegionsTypes()
    }, [])

    useEffect(() => {
        _getRegionsGroups()
    }, [currentMapType?.maps_types_guid, currentMap?.maps_guid])

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    const deleteRegionsGroupFn = async () => {
        const { data } = await client.mutate({
            mutation: deleteRegionsGroup,
            variables: {
                regions_groups_guid: selectedDeleteRegionId,
                locale: 'en'
            }
        })

        const { data: dataStatus } = await client.query({
            query: getTaskStatus,
            variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid }
        })

        if (dataStatus?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
            showToast(dataStatus?.dal_get_task_status[0]?.return_code_locale?.message, 'success')
        } else {
            showToast(dataStatus?.dal_get_task_status[0].return_code_locale.message, 'danger')
        }

        _getRegionsGroups()
    }

    const handleChangeDeleteGroup = (groupId) => {
        setSelectedDeleteRegionId(groupId)
    }

    return (
        <div style={{ display: 'flex' }}>
            <ToastComponent ref={toastRef} />
            <div style={{ display: 'flex', gap: '15px', marginLeft: '15px', alignItems: 'end' }}>

                <section style={{ display: 'block' }}>
                    <label style={{ fontSize: '12px', color: '#757575' }}>Region groups</label>
                    <DropDownListComponent
                        id="region-group-select-label"
                        dataSource={regionsGroups}
                        fields={{ value: 'regions_groups_guid', text: 'regions_groups_name' }}
                        select={({ itemData }) => {
                            onRegionGroupChange(itemData.regions_groups_guid)
                        }}
                        value={currentRegionGroup}
                    />
                </section>

                <Button
                    variant="contained"
                    onClick={() => setRegionsGroupModalVisibility(true)}
                    style={{ marginBottom: '4px' }}
                >Create new region group</Button>
            </div>
            <div style={{ display: 'flex', gap: '15px', marginLeft: '15px', alignItems: 'end' }}>

                <section style={{ display: 'block' }}>
                    <label style={{ fontSize: '12px', color: '#757575' }}>Region groups</label>
                    <DropDownListComponent
                        id="region-group-select-label"
                        dataSource={regionsGroups}
                        fields={{ value: 'regions_groups_guid', text: 'regions_groups_name' }}
                        select={({ itemData }) => {
                            handleChangeDeleteGroup(itemData.regions_groups_guid)
                        }}
                        value={selectedDeleteRegionId}
                    />
                </section>

                <Button
                    variant="contained"
                    onClick={deleteRegionsGroupFn}
                    style={{ marginBottom: '4px' }}
                >Delete region group</Button>
            </div>
        </div>
    )
}

export default RegionGroupsPanel
