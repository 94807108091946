import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import { getUserName, getMenus, getSysMenusList, getUserMenus } from '../apollo/queries';
import { useApolloClient } from "@apollo/react-hooks";




const Summary = () => {
	const user = JSON.parse(localStorage.getItem('user'));
	const locale = localStorage.getItem('locale');
	const navigate = useNavigate();
	const [menu, setMenus] = useState();
	const client = useApolloClient();

	const _getMenusList = async () => {
		const menuList = await client.query({ query: getSysMenusList, variables: { locale: locale, apps_type_uid: "5959df7f-5990-4d7b-9f33-797ed166c687", tenants_uid: user.tenant.tenants_guid } })
		if (menuList?.data?.dal_get_sys_menus_list[0]?.sys_menus_guid) {
			_getMenus();
		}
		else if (!menuList?.data) {
			navigate('/login');
		}
		async function _getMenus() {
			const { data } = await client.query({ query: getUserMenus, variables: { locale: locale, apps_types_uid: "5959df7f-5990-4d7b-9f33-797ed166c687", menus_uid: menuList.data.dal_get_sys_menus_list[0].sys_menus_guid, tenants_uid: user.tenant.tenants_guid, users_uid: user.user_uid } })
			const newArray = []
			const newSections = []

			data.dal_get_apps_menu[0].gql_results.forEach((l, idx) => {
				newArray[idx] = { ...l }
				if (l.sections !== null) {
					l.sections.forEach((s, index) => {
						newSections[index] = { ...s }
					})
				}
			})
			const newMenuArray = newArray.map((l) => {
				l.menus_items_json = typeof (l.menus_items_json) !== 'string' ? '' : JSON.parse(l.menus_items_json)
				if (l.sections !== null) {
					l.sections.forEach((s, index) => {
						newSections[index] = { ...s }
					})
				}
				return l
			})
			if (newMenuArray[0].sys_screens_guid === null) {
				navigate(newMenuArray[1].users_screens_guid)
			}
			else {
				if (newMenuArray[0].sections !== null) {
					navigate(newMenuArray[0].sections[0].users_screens_guid);
				}
				else {
					navigate(newMenuArray[0].users_screens_guid)
				}
			}
		}

	}



	useEffect(() => {
		if (AuthService.isAuthenticated()) {
			_getMenusList()
		}
		else {
			AuthService.logout()
			return navigate('/login')
		}

	}, [])

	return (
		<>

		</>
	)
}

export default Summary