import React, { useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { SidebarContext } from '../../pages/dynamicScreens/Main/context';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useApolloClient } from '@apollo/react-hooks';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../pages/dynamicScreens/screenParameters/input';
import { GlobalTheme } from '../../context/GlobalTheme';
import { SettingsPanel } from '../SettingsPanel';

const Error = ({ children }) => <p style={{ color: "red" }}>{children}</p>;

export const ScreenBar = ({ filterState, setFilterState, setSettingsState, settingsState }) => {

    const toggleDrawer =
        (anchor, open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    (event.key === 'Tab' ||
                        event.key === 'Shift')
                ) {
                    return;
                }

                if (settingsState) {
                    setSettingsState(false)
                }

                setFilterState({ ...filterState, [anchor]: open });
            };

    const context = useContext(SidebarContext)


    const client = useApolloClient();
    const {handleSubmit ,control, setValue, formState : {errors}, } = useForm();


    const _getFormsData = () => {
        context?.screenList?.forEach(s => {
            setValue(s.param, s.param_value)
        })
    }

    useEffect(() => {
        setValue('')
        _getFormsData()


    }, [context?.screenList])


    const formInputs = context?.screenList?.map((c, idx) => {
        const { required, parameter_label, param, query, hidden } = c;

        if (hidden) {
            return null;
        }

        return (
            <section key={idx}>
                <label>{parameter_label}</label>
                {c && <Controller
                    name={param}
                    control={control}
                    rules={{required: required}}
                    defaultValue=""
                    render={({ field }) => (
                        <div>
                            <Input
                                value={field.value}
                                onChange={field.onChange}
                                queryDropdown={query}
                                {...c}
                            />
                        </div>
                    )}
                /> }
                {errors[param] && <Error>This field is required</Error>}
            </section>
        );
    });

    const globalTheme = useContext(GlobalTheme)

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {context?.hasMap && (
                <SettingsPanel
                    settingsState={settingsState}
                    setSettingsState={setSettingsState}
                    filterState={filterState}
                    setFilterState={setFilterState}
                />
            )}
            <React.Fragment>
                <Button onClick={toggleDrawer('right', true)}>
                    <FilterAltIcon style={{ fill: globalTheme[globalTheme.activeTheme]?.svgFill }}/>
                </Button>
                <Drawer
                    anchor={'right'}
                    open={filterState['right']}
                    onClose={toggleDrawer('right', false)}
                >
                    <div style={{ width: 280, paddingTop: 64 }}>
                        <div className="wrapper" style={{ padding: '20px 12px', background: '#fff'}}>
                            <form onSubmit={handleSubmit(context?.onSubmit)}>
                                {/* render the form inputs */}
                                {formInputs}
                                <div style={{ textAlign: "center" }}>
                                    {<ButtonComponent type="submit" cssClass="e-success">
                                        Refresh
                                    </ButtonComponent>}
                                </div>
                            </form>
                        </div>
                    </div>
                </Drawer>
            </React.Fragment>
        </div>
    );
};