import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import PopoverMui from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import SettingsIcon from '@mui/icons-material/Settings';
import FormControlLabel from '@mui/material/FormControlLabel';
import { GET_SINGLE_USER } from '../../apollo/queries';
import { GlobalTheme } from '../../context/GlobalTheme';
import { MaterialUISwitch } from './radio-switch';
import Badge from '@mui/material/Badge';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const Popover = ({ logOut }) => {
    const globalTheme = useContext(GlobalTheme)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const client = useApolloClient();
    const user = JSON.parse(localStorage.getItem('user'));
    const [roles, setRoles] = useState(null);


    const getSingleUser = async () => {
        const { data } = await client.query({
            query: GET_SINGLE_USER,
            variables: { locale: 'en', v_tenants_guid: user.tenant.tenants_guid, v_users_guid: user.user_uid, v_user_id: user.user_uid }
        })
        setRoles(data.dal_get_users_list2?.[0].role_name.toLowerCase())
    }

    const sportsData = [
        { "Id": "default", "Theme": "default" },
        { "Id": "green", "Theme": "green" },
        { "Id": "yellow", "Theme": "yellow" },
    ]

    const fields = { text: 'Theme', value: 'Id' }

    const [value, setValue] = useState(globalTheme?.activeTheme ?? 'default');
    const [text, setText] = useState(globalTheme?.activeTheme ?? 'default');
    // call the change event's function after initialized the component.
    const onChange = (args) => {
        setValue(args.itemData === null ? 'null' : args.itemData[fields.value].toString());
        setText(args.itemData === null ? 'null' : args.itemData[fields.text].toString());
        if (globalTheme?.activeTheme === text + 'dark') {
            globalTheme.setActiveTheme(args.itemData === null ? 'null' : args.itemData[fields.text].toString() + 'dark')
        }
        else {
            globalTheme.setActiveTheme(args.itemData === null ? 'null' : args.itemData[fields.text].toString())
        }

    };

    useEffect(() => {
        getSingleUser()
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Button style={{ minWidth: 40 }} aria-describedby={id} onClick={handleClick}>
                <SettingsIcon style={{ fill: globalTheme[globalTheme.activeTheme]?.svgFill }} />
            </Button>
            <PopoverMui
                id={id}
                sx={{ minHeight: 120, minWidth: 180 }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ flexGrow: 1, bgcolor: globalTheme[globalTheme.activeTheme]?.headerBackground }} variant="h6" className="footer-menu-top">
                    Settings
                </Typography>
                <div style={{ background: globalTheme[globalTheme.activeTheme]?.background }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ p: 2 }}>Mode</Typography>
                        {/*<Switch onChange={() => setRadioChecked(!radioChecked)} checked={radioChecked} {...label} defaultChecked />*/}
                        <FormControlLabel
                            control={<MaterialUISwitch
                                sx={{ m: 1 }}
                                defaultChecked={globalTheme?.activeTheme === text + 'dark'}
                                onChange={(event) => {
                                    // setRadioChecked(!radioChecked)
                                    if (event.target.checked) {
                                        globalTheme.setActiveTheme(text + 'dark')
                                    } else {
                                        globalTheme.setActiveTheme(text)
                                    }
                                }}
                            // checked={radioChecked}
                            />}
                            label=""
                        />
                    </div>
                    <div id='default' style={{ padding: '16px' }}>
                        Theme
                        <DropDownListComponent id="games" dataSource={sportsData} fields={fields} change={onChange} placeholder="Select a theme" value={value} popupHeight="220px" />
                    </div>
                    {
                        roles && roles !== 'admin' ?
                            <Link to="/account">
                                <Typography sx={{ p: 2, borderTop: '1px solid black', color: '#000' }}>Manage account</Typography>
                            </Link>
                            :
                            <Link to="/users-list">
                                <Typography sx={{ p: 2, borderTop: '1px solid black', color: '#000' }}>Manage users</Typography>
                            </Link>
                    }
                    {user.tenant.tenants_types_guid === '6445b703-5226-41fc-b263-070f6f51a0e3' && <Link to="/manage-tenants">
                        <Typography sx={{ p: 2, borderTop: '1px solid black', color: '#000' }}>Manage tenants</Typography>
                    </Link>}

                    <div style={{ marginTop: 180 }}>
                        <Button size="small" onClick={logOut} style={{
                            display: 'flex',
                            marginRight: 20,
                            width: '100%',
                            justifyContent: 'space-between',
                        }}>
                            <Typography sx={{ p: 1, textTransform: 'capitalize', marginRight: 2.5, color: '#000' }}>Logout</Typography>
                            <Badge style={{ marginLeft: 'auto', marginRight: 32 }}>
                                <LogoutIcon style={{ fill: '#000' }} />
                            </Badge>
                        </Button>
                    </div>
                </div>
            </PopoverMui>
        </div>
    );
}