
import { Wrapper, Button } from './styled';

export const Header = ({ setTargetValue, setModalIsOpen }) => {
    const handleClick = () => {
        setModalIsOpen(true)
        setTargetValue('add-new-user')
    }

    return (
        <Wrapper>
            <Button onClick={handleClick} variant="contained">Add new user</Button>
        </Wrapper>
    )
}