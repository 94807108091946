import React from 'react';
import {Route, Routes} from 'react-router-dom';

import ManageRegions from "../mapping/pages/ManageRegions";
import ManageMaps from "../mapping/pages/ManageMaps";

const MappingRoutes = () => {
    return(
        <Routes>
            <Route path="/manage-regions" exact element={<ManageRegions />} />
            <Route path="/manage-maps" exact element={<ManageMaps />} />
        </Routes>
    )
}

export default MappingRoutes;
