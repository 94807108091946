import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useContext, useEffect, useRef, useState } from 'react';
import { OptionsValueContext, SidebarContext } from '../../../../pages/dynamicScreens/Main/context';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { SENSORS } from '../../../../utils/data';
import Box from '@mui/material/Box';
import MuiButton from '@mui/material/Button';

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
}

export const SettingsStaticMap = () => {
    const forceUpdate = useForceUpdate()
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer =
        (anchor, open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    (event.key === 'Tab' ||
                        event.key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const sidebarContext = useContext(SidebarContext)

    const showPointMode = useSelector((state) => state.mainReducer.showPointModeStaticMap); // 1-all points, 2-only final point
    const showAnimationDotMode = useSelector((state) => state.mainReducer.animatedPointsModeStaticMap);
    const sensorsVisible = useSelector((state) => state.mainReducer.visibleSensorTypes);
    const showRegionsPicker = useSelector((state) => state.mainReducer.showRegionsPicker);

    const [isOpen, setOpen] = useState();
    const dispatch = useDispatch();

    let sidebarRef = useRef();
    // useEffect(() => setOpen(isExpanded), [isExpanded]);

    useEffect(() => {
        if (isOpen === true) sidebarRef.current.show();
    }, [isOpen]);

    let editShowPointMode = (event) => {
        dispatch({ type: 'EDIT_SHOW_POINT_MODE_STATIC_MAP', data: event });
    }

    let editAnimationMode = (event) => {
        dispatch({ type: 'EDIT_MODE_STATIC_MAP', data: event });
    }

    let editRegionsPickerVisibility = () => {
        dispatch({ type: 'EDIT_REGIONS_PICKER_VISIBILITY_STATIC_MAP' });
    }

    const [randomNumber, setRandomNumber] = useState(Math.random() * 10000);

    let selectVisibleSensorType = (event) => {
        const label = event.target.id;
        dispatch({
            type: 'EDIT_SENSOR_TYPE_VISIBILITY_STATIC_MAP',
            data: { ...sensorsVisible, [label]: !sensorsVisible[label] }
        });
    }

    let onmouseover = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const editShowMarkers = (event) => {
        sidebarContext.setShowMarkers(event)
    }

    // const everyChecked = useRef(true)
    //
    // console.log('everyChecked', everyChecked)
    //

    useEffect(() => {
        // forceUpdate()
        sidebarContext.forceUpdate = forceUpdate
    }, [])

    useEffect(() => {
        setTimeout(() => {
            forceUpdate()
        })

    }, [randomNumber])


    return (
        <div onMouseDown={onmouseover} className="drawer-wrapper" style={{ width: 280, padding: '64px 20px 0px' }}>
            <div className="sidebar-header">
                <span className="sidebar-settings-title">Settings</span>
            </div>

            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={showAnimationDotMode}
            >
                <FormControlLabel value={1} control={<Radio />} label="Default dot mode"
                    onClick={() => editAnimationMode(1)} />
                <FormControlLabel value={2} control={<Radio />} label="Smoothing dot mode"
                    onClick={() => editAnimationMode(2)} />
            </RadioGroup>

            <hr />

            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={1}
                value={showPointMode}
            >
                <FormControlLabel value={1} control={<Radio />} label="Show all dots"
                    onClick={() => editShowPointMode(1)} />
                {showPointMode === 1 && (
                    <div style={{ paddingLeft: '30px', maxWidth: 'min-content' }}>
                        {Object.keys(sensorsVisible).map(el => (
                            <FormControlLabel
                                key={el}
                                control={(
                                    <Checkbox id={el}
                                        checked={!!sensorsVisible[el]}
                                        onChange={selectVisibleSensorType}
                                    />
                                )}
                                label={SENSORS[el].title}
                                style={{ color: SENSORS[el].color }}
                            />
                        ))}
                    </div>
                )}
                <FormControlLabel value={2} control={<Radio />} label="Show only final dot"
                    onClick={() => editShowPointMode(2)} />
            </RadioGroup>

            <hr />
            {/*1111*/}
            {sidebarContext.showMarkersOnPanel && (
                <>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={showAnimationDotMode}
                    >
                        <FormControlLabel checked={sidebarContext.showMarkers}
                            value={sidebarContext.showMarkers} control={<Radio />}
                            label="Show markers"
                            onClick={() => {
                                forceUpdate()
                                return editShowMarkers(true)
                            }} />
                        <FormControlLabel checked={!sidebarContext.showMarkers}
                            value={sidebarContext.showMarkers} control={<Radio />}
                            label="Hide markers"
                            onClick={() => {
                                forceUpdate()
                                return editShowMarkers(false)
                            }} />
                    </RadioGroup>

                    <hr />
                </>
            )}

            {/* фильтрация регионов по типу */}
            {/*<div className="map-types-dropdown" style={{ right: 16 }}>*/}
            {/*    <section className="dynamic-forms">*/}
            {/*        <label style={{ fontSize: '12px', color: '#757575' }}>Region types</label>*/}
            {/*        <div style={{ display: 'flex' }}>*/}
            {/*            <DropDownListComponent*/}
            {/*                dataSource={sidebarContext.regionsTypesStatic}*/}
            {/*                fields={{ value: 'regions_types_guid', text: 'region_type_name' }}*/}
            {/*                select={({ itemData }) => {*/}
            {/*                    sidebarContext.onMapTypeChangeStatic(itemData.regions_types_guid);*/}
            {/*                }}*/}
            {/*                value={sidebarContext.currentMapStatic?.regions_types_guid}*/}
            {/*            />*/}
            {/*            <button className="dropdown-value-clean-btn" onClick={<sidebarContext className="cleanRegionTypeFilterStatic"></sidebarContext>}> x*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </section>*/}
            {/*</div>*/}

            {/* фильтрация регионов по названию группы и по названию отдельных регионов */}
            {
                sidebarContext.regionsShowStatic?.length > 0 &&
                <div className="map-regions-checkbox-wrapper"
                    style={{ height: '100%', right: 16 }}>
                    {sidebarContext.regionsShowStatic.length > 0 && Object.entries(sidebarContext.filteredRegions).map(regionGroup => {
                        const regionGroupItemState = sidebarContext.regionsShowStatic.find(item => item.regions_groups_name === regionGroup[0])?.targetRow

                        return (
                            <div key={regionGroup.regions_groups_guid}>
                                <FormControlLabel label={regionGroup[0]}
                                    control={
                                        <Checkbox
                                            id={regionGroup[1][0].regions_groups_guid}
                                            size="small"
                                            checked={regionGroupItemState}
                                            onClick={forceUpdate}
                                            onChange={(event) => {
                                                sidebarContext.regionsShowStatic.forEach(item => {
                                                    if (item.regions_groups_name === regionGroup[0]) {
                                                        item['state'] = event.target.checked
                                                    }
                                                })
                                                setRandomNumber(Math.random() * 10000)
                                                sidebarContext.regionGroupCheckStatic(event, regionGroup)
                                                forceUpdate()
                                            }} />
                                    }
                                />
                                {/*regionGroup.state === true &&*/}
                                {regionGroup[1]?.length > 0 &&
                                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 5 }}>
                                        {regionGroup[1].map(region => (
                                            <div key={region.regions_guid}>
                                                <FormControlLabel
                                                    label={region.regions_name}
                                                    control={
                                                        <Checkbox
                                                            id={region.regions_guid}
                                                            size="small"
                                                            checked={region.targetRow || ""}
                                                            onClick={forceUpdate}
                                                            onChange={(event) => {
                                                                sidebarContext.regionCheckStatic(event, regionGroup, region)
                                                                forceUpdate()
                                                            }}
                                                        />}
                                                />
                                            </div>
                                        ))}
                                    </Box>
                                }
                            </div>
                        )
                    }
                    )}
                </div>
            }
        </div>
    );
}