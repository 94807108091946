import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    GridComponent as GridComponentEJ2,
    ColumnsDirective,
    ColumnDirective,
    Inject,
    Filter,
    Sort
} from '@syncfusion/ej2-react-grids'
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import { Tooltip } from '@syncfusion/ej2-react-popups'
import { useApolloClient } from '@apollo/react-hooks'
import { GET_SYS_TENANTS_LIST } from '../../apollo/queries'
import { GlobalTheme } from '../../context/GlobalTheme'
import styled from 'styled-components'
import { ToastComponent } from '@syncfusion/ej2-react-notifications'
import { useNavigate } from 'react-router';
import { SelectedRowContext } from '../../context/SelectedRowContext';

const Wrapper = styled('div')`
  & #Grid_content_table {
    background: ${props => props.bg};
  }
  
  & td {
    color: ${props => props.textColor} !important;
  }
`

const GridComponent = styled(GridComponentEJ2)`
  & .e-content {
    background: ${props => props.bg} !important;
  }
`

export const TenantsList = () => {
    const gridRef = React.createRef();
    const toastRef = useRef(null);
    const navigate = useNavigate();
    const client = useApolloClient();
    const globalTheme = useContext(GlobalTheme)
    const [gridList, setGridList] = useState('')
    const { selectedRow, changeSelectedRow } = useContext(SelectedRowContext);
    const menuItems = [
        {
            text: 'Add New Tenant',
            iconCss: `e-icons e-save`,
            cssClass: 'menu-component-button',
        }

    ]


    const _getTenantsList = async () => {
        const { data } = await client.query({ query: GET_SYS_TENANTS_LIST })
        const transformedData = data.dal_sys_tenants_list_get.map(item => ({
            tenants_guid: item.tenants_guid,
            tenants_type_name: item.tenants_type_name,
            tenant_name: item.tenant_name,
            tenant_description: item.tenant_description,
            is_deactivated: item.is_deactivated,
        }));
        setGridList(transformedData);
    }

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    function rowSelected(g) {
        changeSelectedRow(g.data)
        localStorage.setItem("selectedTenant", JSON.stringify(g.data.tenants_guid));
    }



    const getActionButton = (args) => {
        switch (true) {
            case args.item.properties.text.toLowerCase().includes('add'): {
                return navigate('/add-tenants')
            }
            default:
                return null
        }
    }

    useEffect(() => {
        _getTenantsList()

    }, []);


    return (
        <>
            <ToastComponent ref={toastRef} />
            <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: globalTheme[globalTheme.activeTheme]?.headerBackground, padding: '0 10px', alignItems: 'center', height: '40px', fontSize: '14px', }}>
                Tenants List
                <div className={`${globalTheme?.activeTheme}`} style={{ height: '40px' }}>
                    <MenuComponent items={menuItems} select={getActionButton}></MenuComponent>
                </div>

            </div>
            <Wrapper
                className='control-section'
                bg={globalTheme[globalTheme.activeTheme]?.background}
                textColor={globalTheme[globalTheme.activeTheme]?.color}
                style={{ height: 'calc(100% - 49px)', width: '100%' }}
            >
                <GridComponent
                    ref={gridRef}
                    bg={globalTheme[globalTheme.activeTheme]?.background}
                    id="grid"
                    height={'100%'}
                    dataSource={gridList}
                    allowFiltering={true}
                    allowSorting={true}
                    filterSettings={{
                        type: 'Excel',
                        showFilterBarStatus: true,
                    }}
                    rowSelected={rowSelected}
                    
                >
                    <ColumnsDirective>
                        <ColumnDirective field='tenants_guid' headerText='tenants_guid' width='120'></ColumnDirective>
                        <ColumnDirective field='tenants_type_name' headerText='tenants_type_name' width='120' ></ColumnDirective>
                        <ColumnDirective field='tenant_name' headerText='tenant_name' width='120'  />
                        <ColumnDirective field='tenant_description' headerText='tenant_description' width='120' />
                        <ColumnDirective field='is_deactivated' headerText='is_deactivated' width='120'  ></ColumnDirective>
                    </ColumnsDirective>
                    <Inject services={[Tooltip, Filter, Sort]} />
                </GridComponent>

            </Wrapper>
        </>
    )
}