import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Viewer } from "@grapecity/activereports-react";
import { gql } from "@apollo/client";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useNavigate, useParams } from 'react-router-dom'




const ReportPanel = ({ panel, reportViewer }) => {
    const client = useApolloClient();
    const navigate = useNavigate();
    const { id } = useParams()

    const viewerRef = React.useRef();


    function editReport() {
        navigate(`/edit-report`, { state: { screenId: id } })
    }

    useEffect(() => {
        async function openReport() {
            const report = await reportViewer
            viewerRef.current.Viewer.open(report);
        }
        openReport();
    }, []);


    return (

        <div id="viewer-host" style={{height: 'calc(100% - 49px)'}}>
            <Viewer ref={viewerRef} />
        </div>

    )


}

export default ReportPanel