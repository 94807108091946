import * as React from "react";

export const SelectedRowContext = React.createContext({
    changeSelectedRow: () => {},
    selectedRow: null,
});

export const SelectedRowContextProvider = ({ children }) => {
    const [selectedRow, setSelectedRow] = React.useState(null);
    const selected = React.useMemo(
        () => ({
            changeSelectedRow: (value) => {
                setSelectedRow(value);
                
            },
            selectedRow,
        }),
        [selectedRow]
    );
    


    return(
        <SelectedRowContext.Provider value={selected}>
            {children}
        </SelectedRowContext.Provider>
    );
};

export const useSelectedRow = () => React.useContext(SelectedRowContext);

