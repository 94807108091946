import React, {useRef, useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {MapContainer, TileLayer, useMapEvents} from 'react-leaflet';
import {toast} from "react-toastify";
import L from 'leaflet';
import {ContextMenuComponent} from "@syncfusion/ej2-react-navigations";
import {useApolloClient} from "@apollo/react-hooks";
import useImage from "use-image";
import * as uuid from 'uuid';
import ThIcon from "@patternfly/react-icons/dist/js/icons/th-icon";
import {Button} from "@patternfly/react-core";

import 'leaflet/dist/leaflet.css';
import SynDrawer from '../drawer/drawer';
import './style.css';
import ManagingDrawElementsToolbar from "./dynamic/managingDrawElementsToolbar";
import {
    deleteRegions, deleteRegionsGroupsRegions, updateRegions
} from "../../apollo/mutations";
import {getTaskStatus} from "../../apollo/queries";
import SynModal from "../modals/modal";
import {OPENSTREETMAP_TYPE_GUID, RENDERED_MAP_TYPE_GUID} from "../../../utils/data";
import loader from "../../../assets/loader.gif";
import CreateRegionForm from "../forms/createRegionForm";
import {ContextMenu, ContextMenuWrapper} from './ContextMenu';
import { ToastComponent } from '@syncfusion/ej2-react-notifications'

const mapOptions = {
    fullscreenControl: true,
    // zoomSnap: 1.3,
    zoomSnap: 0.5,
    zoomDelta: 0.5,
    style: { width: '100%', height: '100%'}
}


 // page for draw regions and linking them with groups and maps
// route: site_url/mapping/manage-regions
function ManageRegionsMapPanel() {
    let [center, setCenter] = useState(null);
    let [zoom, setZoom] = useState(null);
    let [isExpanded, setExpanded] = useState(false);
    const [contextMenuShow, setContextMenuShow] = useState({value: false, point: {x: null, y: null}});

    const currentMap = useSelector((state) => state.mainReducer.currentMap);
    const currentMapType = useSelector((state) => state.mainReducer.currentMapType);

    const actionsBtnClick = (event) => {
        setContextMenuShow(prevState => ({...prevState, value: false, point: {x: null, y: null}}));
        setContextMenuShow(prevState => ({...prevState, value: true, point: {x: event.clientX, y: event.clientY}}));
    }

    useEffect(() => {
        return () => {
            setContextMenuShow(prevState => ({...prevState, value: false, point: {x: null, y: null}}));
        };
    } , []);

    useEffect(() => {
        if(currentMap?.maps_view?.maps_views_centroid && currentMap?.maps_view?.maps_views_zoom) {
            if (currentMap.maps_view.maps_views_centroid) {
                setCenter([
                    currentMap.maps_view.maps_views_centroid.coordinates[1],
                    currentMap.maps_view.maps_views_centroid.coordinates[0]
                ])
            }
            if(currentMap.maps_view.maps_views_zoom >= 0) setZoom(currentMap.maps_view.maps_views_zoom);
        } else {
            if(!currentMap || !currentMap?.maps_types_guid || currentMap?.maps_types_guid === "f7ea79b0-bfdc-44c6-a8a0-8a9ff5243589") {
                setCenter([39.748359, -105.004311]);
                setZoom(15);
            } else {
                let image = new Image();
                image.src = currentMap.maps_img;
                image.onload = () => {
                    setCenter([image.height / 2, image.width  / 2]);
                    setZoom(0);
                }
            }
        }
    } , [currentMap]);

    return (
        <>
            {zoom >= 0 && !!center &&
                <div style={{height: '100%'}}>
                    {!currentMapType.maps_types_guid && !currentMap.maps_guid && (
                        <LefleatMap
                            contextMenuShow={contextMenuShow}
                            setContextMenuShow={setContextMenuShow}
                            setExpanded={setExpanded}
                            isExpanded={isExpanded}
                            actionsBtnClick={actionsBtnClick}
                            center={center}
                            zoom={zoom}
                        />
                    )}
                    {currentMapType.maps_types_guid && !currentMap.maps_guid &&
                        <div style={{textAlign: 'center'}}><img src={loader}/></div>
                        // <div style={{textAlign: 'center'}}>Please, select</div>
                    }
                    {currentMap.maps_guid && currentMap.maps_types_guid ?
                        currentMap.maps_types_guid === RENDERED_MAP_TYPE_GUID
                            ? <StaticMap contextMenuShow={contextMenuShow} setContextMenuShow={setContextMenuShow} currentMap={currentMap} setExpanded={setExpanded} isExpanded={isExpanded} actionsBtnClick={actionsBtnClick} center={center} zoom={zoom}/>
                            : currentMap.maps_types_guid === OPENSTREETMAP_TYPE_GUID ?
                                <LefleatMap contextMenuShow={contextMenuShow} setContextMenuShow={setContextMenuShow} setExpanded={setExpanded} isExpanded={isExpanded} actionsBtnClick={actionsBtnClick} center={center} zoom={zoom}/>
                                : <div style={{textAlign: 'center'}}><img src={loader}/></div>
                        : null
                    }
                </div>
            }
        </>
  );
}

const LefleatMap = ({contextMenuShow, setContextMenuShow, setExpanded, isExpanded, actionsBtnClick, center, zoom}) => {
    function MapEvents() {
        useMapEvents({
            mousedown: (event) => {
                L.DomEvent.preventDefault(event.originalEvent);
                L.DomEvent.stopPropagation(event.originalEvent);
            },
        });
        return null;
    }
    const toastRef = useRef(null)
    //

    const [activeCentroid, setActiveCentroid] = useState([])
    // console.log('activeCentroid', activeCentroid)
    return (
        <div className="map-wrapper">
            <ToastComponent ref={toastRef}/>
            <SynDrawer setExpanded={setExpanded} isExpanded={isExpanded} regionsPickerShow={false}/>
            <div className="content">
                <div className="syn-map">

                    <div className='syn-map-actions'>
                        <Button variant="tertiary" icon={<ThIcon />} onClick={actionsBtnClick} />
                    </div>

                    <MapContainer
                        id="map-container"
                        {...mapOptions}
                        center={center}
                        zoom={zoom}
                        maxZoom={25}
                    >
                        <TileLayer
                            noWrap={true}
                            maxZoom={25}
                            maxNativeZoom={19}
                            url='https://api.maptiler.com/maps/basic/256/{z}/{x}/{y}.png?key=Wc6QJbNwbXNaNnv3cva1'
                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                        />
                        <ManagingDrawElementsToolbar setActiveCentroid={setActiveCentroid} toastRef={toastRef}/>
                        <MapEvents />
                        {contextMenuShow.value && (
                            <ContextMenuWrapper
                                activeCentroid={activeCentroid}
                                isOpen={contextMenuShow.value}
                                point={contextMenuShow.point}
                                setContextMenuShow={setContextMenuShow}
                                setExpanded={setExpanded}
                                isExpanded={isExpanded}
                                toastRef={toastRef}
                            />
                        )}
                    </MapContainer>
                </div>
            </div>
        </div>
    )
}


const StaticMap = ({contextMenuShow, setContextMenuShow, currentMap, setExpanded, isExpanded, actionsBtnClick, center, zoom}) => {

    const [map, setMap] = useState(null);

    let [regionsShow, setRegionsShow] = useState([]);

    let [mapImage] = useImage(currentMap.maps_img);

    useEffect(() => {
        if(currentMap?.regions?.length > 0) {
            currentMap.regions.forEach(region => {
                setRegionsShow(prevState => [...prevState, {regions_guid: region.regions_guid, regions_name: region.regions_name,
                    regions_definition: region.regions_definition, state: true}])
            })
        }
    }, [currentMap?.regions]);

    useEffect(() => {
        if(!map && mapImage && document.getElementById('renderedMap')) {
            let currentMapObj = L.map('renderedMap', {
                crs: L.CRS.Simple,
                center: center?.length ? [center[0], center[1]] : [mapImage.height/2, mapImage.width/2],
                zoom: zoom || 0,
                zoomControl: false,
                drawControl: true
            });
            currentMapObj.touchZoom.disable();
            currentMapObj.scrollWheelZoom.disable();
            currentMapObj.dragging.disable();

            currentMapObj.on('click', function(e) {
                console.log(e)
            });

            L.tileLayer('').addTo(currentMapObj);

            let bounds = [[0,0], [mapImage.height, mapImage.width]];
            L.imageOverlay(mapImage, bounds).addTo(currentMapObj);

            currentMapObj.pm.addControls({
                position: 'topright',
                drawCircle: false,
                drawCircleMarker: false,
                drawPolyline: false,
                drawText: false,
                drawMarker: false,
                removalMode: false,
                rotateMode: false
            });
            //
            setMap(currentMapObj)
        }
    }, [map, mapImage]);


    return (
        <div className="map-wrapper">
            <SynDrawer setExpanded={setExpanded} isExpanded={isExpanded} regionsPickerShow={false} />
            <div className="map-content">
                <div className="syn-map">

                    <div className='static-map-actions'>
                        <Button variant="tertiary" icon={<ThIcon/>} onClick={actionsBtnClick}/>
                    </div>

                    <div id="renderedMap" style={{height: '100%'}}>
                        <DrawGeometryComponent map={map} mapImage={mapImage}/>
                        {/*openModal={openModal} setOpenModal={setOpenModal} handleModalTogglePolygon={handleModalTogglePolygon} handleContextMenuToggle={handleContextMenuToggle}*/}

                        {contextMenuShow.value &&
                            <ContextMenu map={map} mapImage={mapImage} isOpen={contextMenuShow.value} point={contextMenuShow.point} setContextMenuShow={setContextMenuShow} setExpanded={setExpanded} isExpanded={isExpanded}/>}
                    </div>
                </div>
            </div>
        </div>
    );
}

// openModal, setOpenModal,handleModalTogglePolygon, handleContextMenuToggle
const DrawGeometryComponent = ({map, mapImage}) => {
    const client = useApolloClient();

    // локальные данные региона, с которым работаем. используются для сохранения данных на беке
    const [regionData, setRegionData] = useState({regions_guid: null, regions_name: null, regions_types_guid: null, regions_definition: {}, regions_centroid: null});
    const [openModal, setOpenModal] = useState(null);

    const regions = useSelector((state) => state.mainReducer.regions);
    const currentRegionGroup = useSelector((state) => state.mainReducer.currentRegionGroup);
    const unsavedRegionsLength = useSelector((state) => state.mainReducer.unsavedRegionsLength);
    const currentMap = useSelector((state) => state.mainReducer.currentMap);
    const dispatch = useDispatch();

    const locale = localStorage.getItem('locale');

    let regionContextMenuRef = useRef();

    useEffect(() => {
        if(map){
            map.on('pm:create', (e) => {
                console.log('create', e)

                switch (e.shape) {
                    case 'Rectangle':
                    case 'Polygon':
                        if(e && e.layer) {
                            let local_id = Date.now();
                            e?.layer && e.layer.setStyle({color: 'gray'});
                            e.layer.properties = {type: 'polygon', local_id, regions_name: '', regions_types_guid: ''};

                            setRegionData({local_id, regions_guid: null, regions_name: '', regions_types_guid: '', regions_definition: e.layer.toGeoJSON(), regions_centroid: null});
                            handleModalToggleRegion(true);

                            // click on region, edit and delete functions
                            e?.layer.on('click',  clickEvent => handleRegionClick(clickEvent))
                        }
                        break;

                    case 'Circle':{
                        break;
                    }
                    case 'Line': {
                        // createRoute(e?.layer);
                        break;
                    }
                    case 'CircleMarker': {
                        // createSensor(e?.layer);
                        break;
                    }
                    default: return;
                }
            })
            map.on('pm:globalcutmodetoggled', e => {
                e.map.pm.setGlobalOptions({
                    snappable: true,
                    snapDistance: 5,
                });

                // e.map.off('click');
                // e.map.pm.getGeomanLayers(true).on('pm:dragend', handleRegionToolbarEvent);
            });

            map.on('pm:globaldragmodetoggled', e => {
                // e.map.off('click');
                e.map.pm.getGeomanLayers(true).on('pm:dragend', handleRegionToolbarEvent);
            });
            map.on('pm:globaleditmodetoggled', e => {
                e.map.pm.getGeomanLayers(true).on('pm:edit', handleRegionToolbarEvent);
            });
            map.on('pm:cut', e => {
                handleRegionToolbarEvent(e)
            });

            return () => {
                map.on('pm:create', () => {});
                map.on('pm:cut', () => {});
                map.on('pm:globaldragmodetoggled', e => {});
                map.on('pm:globaleditmodetoggled', e => {});
            }

        }
        }, [map])


    // drawing saved polygons on the map
    useEffect(() => {
        map?.pm?.getGeomanLayers()?.forEach(layer => {
            if(layer.properties?.type === 'polygon') map.removeLayer(layer)
        })
        dispatch({type: 'SET_UNSAVED_REGIONS_LENGTH', data: 0})
        //
        if(regions.length > 0) {
            regions.forEach(el => {
                if(el.regions_definition.type === "Polygon") {
                    // из-за того что происходит смещение полигонов, добавил умножение координат на 1.037 и 0.965 соотвественно
                    const multiX = currentMap?.maps_types_name === 'Rendered map' ? 1.037 : 1
                    const multiY = currentMap?.maps_types_name === 'Rendered map' ? 0.965 : 1

                    let polygon = L.polygon([el.regions_definition.coordinates.map((c) => c.map((val) => [convertRegionCoordinates('x', val[1] * multiX), convertRegionCoordinates('y', val[0] * multiY)]))]);
                    polygon.properties = {regions_guid: el.regions_guid, regions_name: el.regions_name, regions_types_guid: el.regions_types_guid, type: 'polygon'};

                    polygon.on('click',  clickEvent => handleRegionClick(clickEvent))

                    polygon.bindTooltip(el.regions_name);
                    polygon.addTo(map);

                    console.log(polygon.toGeoJSON().geometry.coordinates)
                }
            })
        }
    }, [map, regions]);

    // from sm to px
    let convertRegionCoordinates = (type, value) => {
        return type === 'x'
            ? mapImage?.width * value / currentMap.maps_real_size.roomW
            : mapImage?.height * value / currentMap.maps_real_size.roomH;
    }

    const handleRegionToolbarEvent = (e) => {
        switch (e?.shape) {
            case 'Cut': {
                if(!e.originalLayer.properties.regions_guid && e.originalLayer.properties.local_id) {
                    e.layer.properties = { local_id: e.originalLayer.properties.local_id, regions_types_guid: e.originalLayer.properties.regions_types_guid,
                        regions_name: e.originalLayer.properties.regions_name, type: 'cut-polygon'};
                } else if(e.originalLayer.properties.regions_guid) {
                    e.layer.properties = { regions_guid: e.originalLayer.properties.regions_guid, regions_types_guid: e.originalLayer.properties.regions_types_guid,
                        regions_name: e.originalLayer.properties.regions_name, type: 'cut-polygon'};
                    updateRegion(e.layer);
                }
                break;
            }
            case 'Rectangle':
            case 'Polygon':
                if(e.originalLayer.properties.regions_guid) {
                    updateRegion(e.layer);
                }
                break;
            default: return;
        }
    }

    const handleModalToggleRegion = (value) => setOpenModal(value ? 'polygon' : undefined);
    const handleContextMenuToggle = (value) => setOpenModal(value ? 'contextMenu' : undefined);

    const handleRegionClick = async (clickEvent) => {
        if(!map.pm.globalCutModeEnabled() && !map.pm.globalDragModeEnabled() && !map.pm.globalEditModeEnabled()) {
            setRegionData({local_id: clickEvent.target.properties.local_id ? clickEvent.target.properties.local_id : null, regions_guid: clickEvent.target.properties.regions_guid, regions_name: clickEvent.target.properties.regions_name, regions_types_guid: clickEvent.target.properties.regions_types_guid, regions_definition: clickEvent.target.toGeoJSON(), regions_centroid: null});
            handleContextMenuToggle(true);
            regionContextMenuRef.current?.open(clickEvent.originalEvent.clientY, clickEvent.originalEvent.clientX);
        }
    }

    const saveRegionData = async () => {
        if(!regionData.regions_name || !regionData.regions_types_guid) toast.error('Please fill in all the fields');
        else {
            if(regionData.regions_guid) { // edit region
                let currentRegion = map.pm.getGeomanLayers().find(el => el.properties?.regions_guid === regionData?.regions_guid);
                currentRegion.properties = {...currentRegion.properties, regions_name: regionData.regions_name, regions_types_guid: regionData.regions_types_guid}
                currentRegion.bindTooltip(regionData.regions_name);
                updateRegion(currentRegion);
                handleModalToggleRegion(false);
            } else { // create region
                console.log(map.pm.getGeomanLayers())
                console.log(regionData.local_id)
                let currentRegion = map.pm.getGeomanLayers().find(el => el.properties?.local_id === regionData?.local_id);
                currentRegion.properties = {...currentRegion.properties, regions_name: regionData.regions_name, regions_types_guid: regionData.regions_types_guid}
                currentRegion.bindTooltip(regionData.regions_name);
                // setCurrentSelectedPolygon(currentRegion);
                dispatch({type: 'SET_UNSAVED_REGIONS_LENGTH', data: unsavedRegionsLength + 1})
                handleModalToggleRegion(false);
            }
        }
    }

    const updateRegion = async (region) => {
        try {
            let geometryCoordinates;
            let regions_definition = 'POLYGON ((';
            // let regions_definition = region.properties.type.toUpperCase() + ' ((';

            if(region.properties.type === 'cut-polygon') {
                geometryCoordinates = region.toGeoJSON().geometry.coordinates;
                geometryCoordinates.forEach(el => {
                    el.forEach(coord => regions_definition += coord[0] + ' ' +  coord[1] + ', ');

                    regions_definition = regions_definition.slice(0, -2);
                    regions_definition += '), (';
                });

                regions_definition = regions_definition.slice(0, -3);
                regions_definition += ')';
            } else {
                geometryCoordinates = region.toGeoJSON().geometry.coordinates[0].length > 1 ? region.toGeoJSON().geometry.coordinates[0] :
                    region.toGeoJSON().geometry.coordinates[0].length === 1 && region.toGeoJSON().geometry.coordinates[0][0].length > 1 ? region.toGeoJSON().geometry.coordinates[0][0] : [];

                geometryCoordinates.forEach(el => regions_definition += el[0] + ' ' +  el[1] + ', ');
                regions_definition = regions_definition.slice(0, -2) + '))';
            }

            const {data} = await client.mutate({
                mutation: updateRegions,
                variables: {
                    regions_guid: region.properties.regions_guid,
                    regions_name: region.properties.regions_name,
                    regions_types_guid: region.properties.regions_types_guid,
                    regions_definition,
                    regions_centroid: 'point (' + region.getBounds().getCenter().lng + ' ' + region.getBounds().getCenter().lat + ')',
                    locale: locale ? locale : "en"
                }
            })
            if(data?.insert_tasks_tasks?.returning) {
                const status = await client.query({
                    query: getTaskStatus,
                    variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}
                })
                if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                    toast.success("Region was edited");
                } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                    toast.error(`Something went wrong!`);
                }
            }
        }
        catch (e) {
            console.error(e.message);
        }
    }

    const handleDeleteRegion = async () => {
        if(regionData.regions_guid) {
            // сначала отвязать регион от группы, потом удалить регион из базы, потом стереть его с карты
            const {data} = await client.mutate({
                mutation: deleteRegionsGroupsRegions,
                variables: {
                    regions_groups_guid: currentRegionGroup,
                    regions_guid: regionData.regions_guid,
                    locale: locale ? locale : "en"
                }
            })
            if(data?.insert_tasks_tasks?.returning) {
                const status = await client.query({
                    query: getTaskStatus,
                    variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}
                })
                if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                    deleteRegion(regionData.regions_guid);
                } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                    toast.error(`Something went wrong!`);
                }
            }
        } else {
            map.pm.getGeomanLayers().find(el => el.properties?.local_id === regionData?.local_id).pm.remove();
        }
        setRegionData({regions_guid: null, regions_name: '', regions_groups_guid: '', regions_types_guid: '', regions_definition: {}, regions_centroid: null});
    }

    const deleteRegion = async (regions_guid) => {
        const {data} = await client.mutate({
            mutation: deleteRegions,
            variables: {
                regions_guid,
                locale: locale ? locale : "en"
            }
        })
        if(data?.insert_tasks_tasks?.returning) {
            const status = await client.query({
                query: getTaskStatus,
                variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}
            })
            if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                map.pm.getGeomanLayers().find(el => el.properties?.regions_guid === regions_guid)?.pm?.remove();
                toast.success("Region was deleted");
            } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                toast.error(`Something went wrong!`);
            }
        }
    }

    const cancelRegion = () => {
        if(!regionData.regions_guid && !regionData.regions_name && !regionData.regions_types_guid && regionData.local_id) map.pm.getGeomanLayers().find(el => el.properties?.local_id === regionData.local_id).pm.remove();
        setRegionData({
            regions_guid: null,
            regions_name: '',
            regions_groups_guid: '',
            regions_types_guid: '',
            regions_definition: {},
            regions_centroid: null
        });
        handleModalToggleRegion(false);
    }

    let onPropertyChanged = (event) => {
        switch (event.item.id) {
            case 'edit': handleModalToggleRegion(true); break;
            case 'delete': handleDeleteRegion(); break;
            default: break;
        }
    }

    let regionContextMenuRefItems = [
        {id: 'edit', text: 'Edit'},
        {id: 'delete', text: 'Delete'}
    ]

    switch (openModal) {
        case 'contextMenu': return (
            <ContextMenuComponent
                id='polygonContextMenu'
                ref={regionContextMenuRef}
                items={regionContextMenuRefItems}
                select={onPropertyChanged}
            />
        )
        case 'polygon': return (
            <SynModal
                title="Region"
                openModal={openModal === 'polygon'}
                setOpenModal={handleModalToggleRegion}
                save={saveRegionData}
                cancel={cancelRegion}
                content={<CreateRegionForm setFormData={setRegionData} formData={regionData}/>}
            />
        )
        default: return null;
    }
}

export default ManageRegionsMapPanel;
