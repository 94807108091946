import { useState } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";


const required = (value) => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                This field is required!
            </div>
        );
    }
};

const LoginForm = ({ handleLogin, loading, message }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    return (
        <Form onSubmit={handleLogin}>
            <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                    type="text"
                    className="form-control"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    validations={[required]}
                />
            </div>

            <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    validations={[required]}
                />
            </div>

            <div className="form-group">
                <button
                    style={{ marginTop: "10px", marginLeft: "100px" }}
                    className="btn btn-primary btn-block"
                    disabled={loading}
                >
                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                    <span>Login</span>
                </button>
            </div>

            {message && (
                <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                        {message}
                    </div>
                </div>
            )}

            <CheckButton style={{ display: "none" }} />
        </Form>
    );
};

export default LoginForm;