import { createContext, useState } from 'react';


export const theme = {

    default: {
        background: '#fff',
        headerBackground: '#bbd2eb',
        svgFill: '#000',
        color: '#000',
        hover: '#f5f5f5',
    },
    defaultdark: {
        background: '#2a2a2a',
        headerBackground: '#2a2a2a',
        color: '#fff',
        svgFill: '#fff',
        hover: '#525555',
    },
    green: {
        background: '#fff',
        headerBackground: '#1abc9c',
        svgFill: '#000',
        color: '#000',
        hover: '#2ecc71'
    },
    greendark: {
        background: '#0e8243',
        headerBackground: '#0b6047',
        svgFill: '#fff',
        color: '#fff',
        hover: '#16a085',
    },
    yellow: {
        background: '#fff',
        headerBackground: '#f6e58d',
        svgFill: '#000',
        color: '#000',
        hover: '##f9ca24'
    },
    yellowdark: {
        background: '#f6e58d',
        headerBackground: '#f9ca24',
        svgFill: '#000',
        color: '#000',
        hover: '#222',
    },
    activeTheme: 'default',
    setActiveTheme: (theme) => { },
}


export const GlobalTheme = createContext(theme)
