import React, { useCallback, useContext } from 'react';
import {useDispatch, useSelector} from "react-redux";
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import '@geoman-io/leaflet-geoman-free';
import {useState, useEffect, useRef} from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import {useApolloClient} from '@apollo/react-hooks';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import L from "leaflet";
import {toast} from "react-toastify";

import SynModal from "../../modals/modal";
import SynCreatePolygonForm from "../../forms/createPolygonForm";
import {
  addRegions,
  deleteRegions,
  deleteRegionsGroupsRegions,
  updateRegions
} from "../../../apollo/mutations";
import {getTaskStatus} from "../../../../apollo/queries";
import CreateRegionForm from "../../forms/createRegionForm";
import { ToastComponent } from '@syncfusion/ej2-react-notifications'

const SynDrawElementsToolbar = ({ toastRef, setActiveCentroid }) => {
  const client = useApolloClient();

  const [openModal, setOpenModal] = useState(null);

  // локальные данные региона, с которым работаем. используются для сохранения данных на беке
  const [regionData, setRegionData] = useState({regions_guid: null, regions_name: null, regions_types_guid: null, regions_definition: {}, regions_centroid: null});
  const [lastHoleData, setLastHoleData] = useState({id: null, coordinates: []});

  let regionContextMenuRef = useRef();

  const dispatch = useDispatch();
  const locale = localStorage.getItem('locale');

  const regions = useSelector((state) => state.mainReducer.regions);
  const currentRegionGroup = useSelector((state) => state.mainReducer.currentRegionGroup);
  const unsavedRegionsLength = useSelector((state) => state.mainReducer.unsavedRegionsLength);

  const map = useMap();

  const MapEvents = useMapEvents({
    mousemove: (e) => {
      const centroid = e?.target?.getCenter?.()
      // console.log('[centroid.lat, centroid.lng]', [centroid.lat, centroid.lng])
      if (centroid) {
        setActiveCentroid([centroid.lng, centroid.lat])
      }
    }
  });

  const handleRegionToolbarEvent = useCallback((e) => {
    switch (e?.shape) {
      case 'Cut': {
        console.log('cut ', e);
        // save the last hole id

        if(!e.originalLayer.properties.regions_guid && e.originalLayer.properties.local_id) {
          e.layer.properties = { local_id: e.originalLayer.properties.local_id, regions_types_guid: e.originalLayer.properties.regions_types_guid,
            regions_name: e.originalLayer.properties.regions_name, type: 'cut-polygon'};
        } else if(e.originalLayer.properties.regions_guid) {
          e.layer.properties = { regions_guid: e.originalLayer.properties.regions_guid, regions_types_guid: e.originalLayer.properties.regions_types_guid,
            regions_name: e.originalLayer.properties.regions_name, type: 'cut-polygon'};
          updateRegion(e.layer);
        }


        // console.log('######  ', e.originalLayer.toGeoJSON());
        // console.log('######  ', e.layer.toGeoJSON());

        // if(e.layer.toGeoJSON()?.geometry?.coordinates.length === 2){
        //   console.log('###### IF ', e.layer?.toGeoJSON()?.geometry?.coordinates[1]);
        //   setLastHoleData(() => ({id: e.originalLayer.properties.local_id, coordinates: e.layer?.toGeoJSON()?.geometry?.coordinates[1]}));
        // } else {
        //   console.log('###### ELSE ', e.layer?.toGeoJSON()?.geometry?.coordinates);
        //   for(let i = 1; i < e.layer.toGeoJSON()?.geometry?.coordinates.length - 1; i++) {
        //     console.log('###### FOR ', i);
        //     console.log(e.layer.toGeoJSON()?.geometry?.coordinates[i], lastHoleData);
        //
        //     if(e.layer.toGeoJSON()?.geometry?.coordinates[i].length === lastHoleData.coordinates.length) {
        //       for(let j = 0; j < lastHoleData.coordinates[i].length - 1; j++) {
        //         console.log(lastHoleData.coordinates[i])
        //         // if()
        //         // if(j < lastHoleData.coordinates[j].length - 1)
        //       }
        //
        //
        //     }
        //   }
        // }



        // if(e.layer?.toGeoJSON()?.geometry?.coordinates.length === 2)
        // setLastHoleData({id: e.originalLayer.properties.local_id || e.originalLayer.properties.regions_guid,
        //   coordinates: e.layer?.toGeoJSON()?.geometry?.coordinates.length === 2 ? e.layer?.toGeoJSON()?.geometry?.coordinates[e.layer?.toGeoJSON()?.geometry?.coordinates.length - 1]
        // :
        // })

        break;
      }
      case 'Rectangle':
      case 'Polygon':
        if(e.originalLayer.properties.regions_guid) {
          updateRegion(e.layer);
        }
        break;
      default: return;
    }
  }, [])
  // lastHoleData

  map.pm.addControls({
    position: 'topright',
    drawCircle: false,
    drawCircleMarker: false,
    drawPolyline: false,
    drawText: false,
    drawMarker: false,
    removalMode: false,
    rotateMode: false
  });

  useEffect(() => {
    if(map){
      map.on('pm:create', (e) => {
        console.log('create ', e)
        switch (e.shape) {
          case 'Rectangle':
          case 'Polygon':
            if(e && e.layer) {

              console.log('create  e.layer toGeoJSON ', e.layer?.toGeoJSON()?.geometry?.coordinates);

              let local_id = Date.now();
              e?.layer && e.layer.setStyle({color: 'gray'});
              e.layer.properties = {type: 'polygon', local_id, regions_name: '', regions_types_guid: ''};

              setRegionData({local_id, regions_guid: null, regions_name: '', regions_types_guid: '', regions_definition: e.layer.toGeoJSON(), regions_centroid: null});
              handleModalToggleRegion(true);

              // click on region, edit and delete functions
              e?.layer.on('click',  clickEvent => handleRegionClick(clickEvent))
            }
            break;
          default: return;
        }
      })

      map.on('pm:globalcutmodetoggled', e => {
        e.map.pm.setGlobalOptions({
          snappable: true,
          snapDistance: 5,
        });

        // e.map.off('click');
        // e.map.pm.getGeomanLayers(true).on('pm:dragend', handleRegionToolbarEvent);
      });

      map.on('pm:globaldragmodetoggled', e => {
        // e.map.off('click');
        e.map.pm.getGeomanLayers(true).on('pm:dragend', handleRegionToolbarEvent);
      });
      map.on('pm:globaleditmodetoggled', e => {
        e.map.pm.getGeomanLayers(true).on('pm:edit', handleRegionToolbarEvent);
      });
      map.on('pm:cut', handleRegionToolbarEvent);
    }
    return () => {
      map.on('pm:create', () => {});
      map.on('pm:cut', () => {});
      map.on('pm:globaldragmodetoggled', e => {});
      map.on('pm:globaleditmodetoggled', e => {});
    }
  }, [map, handleRegionToolbarEvent]);


  // drawing saved polygons on the map
  useEffect(() => {
    map.pm.getGeomanLayers().forEach(layer => {
      if(layer.properties?.type === 'polygon') map.removeLayer(layer)
    })
    dispatch({type: 'SET_UNSAVED_REGIONS_LENGTH', data: 0})

    if(regions.length > 0) {
      regions.forEach(el => {
        if(el.regions_definition.type === "Polygon") {
          let polygon = L.polygon([el.regions_definition.coordinates.map((c) => c.map((val) => [val[1], val[0]]))]);
          polygon.properties = {regions_guid: el.regions_guid, regions_name: el.regions_name, regions_types_guid: el.regions_types_guid, type: 'polygon'};

          polygon.on('click',  clickEvent => handleRegionClick(clickEvent))

          polygon.bindTooltip(el.regions_name);
          polygon.addTo(map);
        }
      })
    }
  }, [regions]);

  const showToast = (message, type) => {
    toastRef.current.show({
      content: message,
      cssClass: `e-toast-${type}`,
      position: { X: 'Right', Y: 'Top' },
      animation: { show: { duration: 500 }, hide: { duration: 500 } },
      timeOut: 3000,
    });
  }

  const handleModalToggleRegion = (value) => setOpenModal(value ? 'polygon' : undefined);
  const handleContextMenuToggle = (value) => setOpenModal(value ? 'contextMenu' : undefined);

  const handleRegionClick = async (clickEvent) => {
    if(!map.pm.globalCutModeEnabled() && !map.pm.globalDragModeEnabled() && !map.pm.globalEditModeEnabled()) {
      setRegionData({local_id: clickEvent.target.properties.local_id ? clickEvent.target.properties.local_id : null, regions_guid: clickEvent.target.properties.regions_guid, regions_name: clickEvent.target.properties.regions_name, regions_types_guid: clickEvent.target.properties.regions_types_guid, regions_definition: clickEvent.target.toGeoJSON(), regions_centroid: null});
      handleContextMenuToggle(true);
      regionContextMenuRef.current?.open(clickEvent.originalEvent.clientY, clickEvent.originalEvent.clientX);
    }
  }

  const saveRegionData = async () => {
    if(!regionData.regions_name || !regionData.regions_types_guid) {
        showToast('Please fill in all the fields', 'error')
    }
    else {
      if(regionData.regions_guid) { // edit region
        let currentRegion = map.pm.getGeomanLayers().find(el => el.properties.regions_guid === regionData.regions_guid);
        currentRegion.properties = {...currentRegion.properties, regions_name: regionData.regions_name, regions_types_guid: regionData.regions_types_guid}
        currentRegion.bindTooltip(regionData.regions_name);
        updateRegion(currentRegion);
        handleModalToggleRegion(false);
      } else { // create region
        let currentRegion = map.pm.getGeomanLayers().find(el => el.properties?.local_id === regionData.local_id);
        currentRegion.properties = {...currentRegion.properties, regions_name: regionData.regions_name, regions_types_guid: regionData.regions_types_guid}
        currentRegion.bindTooltip(regionData.regions_name);
        // setCurrentSelectedPolygon(currentRegion);
        dispatch({type: 'SET_UNSAVED_REGIONS_LENGTH', data: unsavedRegionsLength + 1})
        handleModalToggleRegion(false);
      }
    }
  }

  const updateRegion = async (region) => {
    try {
      let geometryCoordinates;
      let regions_definition = 'POLYGON ((';
      // let regions_definition = region.properties.type.toUpperCase() + ' ((';

      if(region.properties.type === 'cut-polygon') {
        geometryCoordinates = region.toGeoJSON().geometry.coordinates;
        geometryCoordinates.forEach(el => {
          el.forEach(coord => regions_definition += coord[0] + ' ' +  coord[1] + ', ');

          regions_definition = regions_definition.slice(0, -2);
          regions_definition += '), (';
        });

        regions_definition = regions_definition.slice(0, -3);
        regions_definition += ')';
      } else {
        geometryCoordinates = region.toGeoJSON().geometry.coordinates[0].length > 1 ? region.toGeoJSON().geometry.coordinates[0] :
            region.toGeoJSON().geometry.coordinates[0].length === 1 && region.toGeoJSON().geometry.coordinates[0][0].length > 1 ? region.toGeoJSON().geometry.coordinates[0][0] : [];

        geometryCoordinates.forEach(el => regions_definition += el[0] + ' ' +  el[1] + ', ');
        regions_definition = regions_definition.slice(0, -2) + '))';
      }

      const {data} = await client.mutate({
        mutation: updateRegions,
        variables: {
          regions_guid: region.properties.regions_guid,
          regions_name: region.properties.regions_name,
          regions_types_guid: region.properties.regions_types_guid,
          regions_definition,
          regions_centroid: 'point (' + region.getBounds().getCenter().lng + ' ' + region.getBounds().getCenter().lat + ')',
          locale: locale ? locale : "en"
        }
      })
      if(data?.insert_tasks_tasks?.returning) {
        const status = await client.query({
          query: getTaskStatus,
          variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}
        })
        if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
            showToast('Region was edited', 'success')
        } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
            showToast('Something went wrong!', 'error')
        }
      }
    }
    catch (e) {
      console.error(e.message);
    }
  }

  const handleDeleteRegion = async () => {
    if(regionData.regions_guid) {
      // сначала отвязать регион от группы, потом удалить регион из базы, потом стереть его с карты
      const {data} = await client.mutate({
        mutation: deleteRegionsGroupsRegions,
        variables: {
          regions_groups_guid: currentRegionGroup,
          regions_guid: regionData.regions_guid,
          locale: locale ? locale : "en"
        }
      })
      if(data?.insert_tasks_tasks?.returning) {
        const status = await client.query({
          query: getTaskStatus,
          variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}
        })
        if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
          deleteRegion(regionData.regions_guid);
        } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
            showToast('Something went wrong!', 'error')
        }
      }
    } else {
      map.pm.getGeomanLayers().find(el => el.properties.regions_guid === regionData.regions_guid).pm.remove();
    }
    setRegionData({regions_guid: null, regions_name: '', regions_groups_guid: '', regions_types_guid: '', regions_definition: {}, regions_centroid: null});
  }

  const deleteRegion = async (regions_guid) => {
    const {data} = await client.mutate({
      mutation: deleteRegions,
      variables: {
        regions_guid,
        locale: locale ? locale : "en"
      }
    })
    if(data?.insert_tasks_tasks?.returning) {
      const status = await client.query({
        query: getTaskStatus,
        variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}
      })
      if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
        map.pm.getGeomanLayers().find(el => el.properties.regions_guid === regions_guid).pm.remove();
        showToast('Region was deleted', 'success')
      } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
        showToast('Something went wrong!', 'error')
      }
    }
  }

  const cancelRegion = () => {
    if(!regionData.regions_guid && !regionData.regions_name && !regionData.regions_types_guid && regionData.local_id) map.pm.getGeomanLayers().find(el => el.properties?.local_id === regionData.local_id).pm.remove();
    setRegionData({
      regions_guid: null,
      regions_name: '',
      regions_groups_guid: '',
      regions_types_guid: '',
      regions_definition: {},
      regions_centroid: null
    });
    handleModalToggleRegion(false);
  }

  let onPropertyChanged = (event) => {
    switch (event.item.id) {
      case 'edit': handleModalToggleRegion(true); break;
      case 'delete': handleDeleteRegion(); break;
      default: break;
    }
  }

  let regionContextMenuRefItems = [
    {id: 'edit', text: 'Edit'},
    {id: 'delete', text: 'Delete'}
  ]

  switch (openModal) {
    case 'contextMenu': return (
        <>
          {/*<MapEvents />*/}
          <ContextMenuComponent id='regionContextMenuRef' ref={regionContextMenuRef} items={regionContextMenuRefItems} select={onPropertyChanged}/>
        </>
    );
    case 'polygon': return (
        <>
          {/*<MapEvents />*/}
          <SynModal
              title="Region"
              openModal={openModal === 'polygon'}
              setOpenModal={handleModalToggleRegion}
              save={saveRegionData}
              cancel={cancelRegion}
              content={<CreateRegionForm setFormData={setRegionData} formData={regionData}/>}
          />
        </>
    );
    default: return null;
  }
}

export default SynDrawElementsToolbar;
