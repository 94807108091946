import React, { useState, useEffect, useRef, createContext, useMemo, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useLocation } from 'react-router';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import RGL, { Responsive, WidthProvider } from "react-grid-layout";

import { SelectedRowContextProvider } from '../../../context/SelectedRowContext';
import { ActionButtonContextProvider } from '../../../context/ActionButtonContext';
import PanelsContent from './panelsContent';
import PanelsHeader from './panelsHeader';
import { getDynamicScreen, getDynamicScreenSwitch } from '../../../apollo/queries';
import Sidebar from '../../../components/Sidebar';
import { OptionsValueContext, SidebarContext, ScreenNameContext } from './context';
import { GlobalTheme } from '../../../context/GlobalTheme';

const ReactGridLayout = WidthProvider(Responsive);

const DynamicScreen = () => {
    const originalLayouts = getFromLS("layouts") || {};
    const client = useApolloClient();
    const location = useLocation();
    const user = JSON.parse(localStorage.getItem('user'));
    const locale = localStorage.getItem('locale');
    const { id } = useParams();
    const [panelsList, setPanelsList] = useState();
    const [screenList, setScreenList] = useState();
    const [sidebarScreenParameters, setSidebarScreenParameters] = useState(null);
    const [locationState, setLocationState] = useState('');
    const isOpenStreet = panelsList?.find(item => item.isOpenStreet);
    const [layouts, setLayouts] = useState(JSON.parse(JSON.stringify(originalLayouts)));
    const [screenName, setScreenName] = useState('');
    const [screenType, setScreenType] = useState('efabc6ad-1a62-4fa6-9c83-0427b693ea0f');
    const [isScreenEditable, setIsScreenEditable] = useState(false);
    const [isScreenDraggable, setIsScreenDraggable] = useState(false);
    const [dynamicGridHeight, setDynamicGridHeight] = useState(window.innerHeight);
    const [fixedGridHeight, setFixedGridHeight] = useState(50);

    const getDynamicGridHeight = (h) => {
        const windowHeight = window.innerHeight - 80;
        const dynamicGridHeight = (windowHeight - (10 * (h - 1))) / h;
        return dynamicGridHeight;
      };

    useEffect(() => {
        const handleResize = () => {
            const dynamicGridHeight = getDynamicGridHeight(h);
            setDynamicGridHeight(dynamicGridHeight);
        };

        const h = 12;

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const calculateGridHeight = (layout, item) => {
        const gridItem = document.getElementById(item.i);
        const pivotElement = gridItem.querySelector('.pivots div');
        const toolbarPivotElement = gridItem.querySelector('.pivots div div');
        if (pivotElement && pivotElement.ej2_instances && pivotElement.ej2_instances.length > 0) {
            const pivotObj = pivotElement.ej2_instances?.[0]
            const gridHeight = gridItem.clientHeight - 50;
            pivotObj.height = gridHeight;
            pivotObj.width = '100%';
            pivotObj.refresh();
        } else if (toolbarPivotElement && toolbarPivotElement.ej2_instances && toolbarPivotElement.ej2_instances.length > 0) {
            const pivotObj = toolbarPivotElement.ej2_instances?.[0]
            const gridHeight = gridItem.clientHeight - 50;
            pivotObj.height = gridHeight;
            pivotObj.width = '100%';
            pivotObj.refresh();
        } else {
            return;
        }
    };


    function getFromLS(key) {
        let ls = {};
        if (global.localStorage) {
            try {
                ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
            } catch (e) {
                /*Ignore*/
            }
        }
        return ls[key];
    }


    const _getDynamicScreen = async () => {
        if (location.state) {
            if (location.state?.param_value?.action_name.toLowerCase().includes('edit')) {
                const parameters = JSON.stringify(location.state?.param_value?.action_parameters)
                const { data } = await client.query({
                    query: getDynamicScreenSwitch,
                    variables: {
                        locale: locale,
                        actions_flags_uid: location.state?.param_value?.sys_actions_flags_guid,
                        screens_paels_uid: location.state?.param_value?.sys_screens_panels_guid,
                        screen_parameter_values: parameters, tenants_uid: user.tenant.tenants_guid
                    }
                })
                setScreenType(data.dal_get_sys_apps_screens_switch[0].gql_results[0]?.sys_screens_layouts_types_guid)
                setScreenName(data.dal_get_sys_apps_screens_switch[0].gql_results[0].screen_name)
                setScreenList(data.dal_get_sys_apps_screens_switch[0].gql_results[0].screen_parameters)
                setPanelsList(data.dal_get_sys_apps_screens_switch[0].gql_results[0].panels)
                setIsScreenEditable(data.dal_get_sys_apps_screens_switch[0].gql_results[0].is_editable)
                setIsScreenDraggable(data.dal_get_sys_apps_screens_switch[0].gql_results[0].is_dynamic)
            }
            else {
                const parameters = JSON.stringify(location.state?.param_value?.action_parameters)
                const { data } = await client.query({
                    query: getDynamicScreen,
                    variables: {
                        locale: locale, actions_flags_uid: location.state?.param_value?.sys_actions_flags_guid,
                        screens_uid: id, screen_parameter_values: parameters, tenants_uid: user.tenant.tenants_guid
                    }
                })
                setScreenType(data.dal_get_sys_apps_screens[0].gql_results[0]?.sys_screens_layouts_types_guid)
                setScreenName(data.dal_get_sys_apps_screens[0].gql_results[0].screen_name)
                setScreenList(data.dal_get_sys_apps_screens[0].gql_results[0].screen_parameters)
                setPanelsList(data.dal_get_sys_apps_screens[0].gql_results[0].panels.slice().reverse())
                setIsScreenEditable(data.dal_get_sys_apps_screens[0].gql_results[0].is_editable)
                setIsScreenDraggable(data.dal_get_sys_apps_screens[0].gql_results[0].is_dynamic)
            }
        }
        else {
            const { data } = await client.query({
                query: getDynamicScreen,
                variables: {
                    locale: locale, screens_uid: id,
                    tenants_uid: user.tenant.tenants_guid
                }
            })
            setScreenType(data.dal_get_sys_apps_screens[0].gql_results[0]?.sys_screens_layouts_types_guid)
            setScreenName(data.dal_get_sys_apps_screens[0].gql_results[0].screen_name)
            setScreenList(data.dal_get_sys_apps_screens[0].gql_results[0].screen_parameters)
            const resultPanels = data.dal_get_sys_apps_screens[0].gql_results[0].panels.map(panel => {
                if (panel.sys_panels_guid === '5928482d-a7eb-4581-9a88-0c517139a14a' || panel.sys_panels_guid === '1b643b33-155e-452a-97d8-5560cdaa33f1' || panel.sys_panels_guid === '45408c6b-4bae-4247-890b-a9a8dfce7051' || panel.sys_panels_guid === '76c93d07-1d5c-43e6-b582-1eed34e21b91' || panel.sys_panels_guid === '57151a0e-b131-4dad-9326-e56fbf9dfa49') {
                    return { ...panel, isOpenStreet: true }
                } else {
                    return { ...panel, isOpenStreet: false }
                }
                return panel
            })

            setPanelsList(resultPanels.reverse())
            setIsScreenEditable(data.dal_get_sys_apps_screens[0].gql_results[0].is_editable)
            setIsScreenDraggable(data.dal_get_sys_apps_screens[0].gql_results[0].is_dynamic)
        }
    }


    useEffect(() => {
        _getDynamicScreen()

        return () => {
            setPanelsList(null);
            setLocationState(null);
            setScreenList(null);
            setSidebarScreenParameters(null);
            setIsScreenEditable(false);
            setIsScreenDraggable(false);
        };
    }, [id]);

    const onSubmit = (info) => {
        if (info) {
            setSidebarScreenParameters(info)
        }
    }

    function onLayoutChange(layout, layouts) {
        calculateGridHeightOnMount(layout);
        setLayouts(layouts);
    }

    const calculateGridHeightOnMount = (layout) => {
        layout.forEach(layoutItem => {
            calculateGridHeight(layout, layoutItem);
        })
    }

    const hasMap = panelsList?.some(panel => panel?.panel_json?.element_type === 'map')
    const isSvgMap = panelsList?.some(panel => panel?.sys_screens_guid === '67d75f7d-5d86-41ac-8d54-11e650d2c486')
    const globalTheme = useContext(GlobalTheme)

    return (
        <>
            <SelectedRowContextProvider>
                <ActionButtonContextProvider>
                    <OptionsValueContext.Provider value={{
                        isOpenStreet,
                        panelsLength: panelsList?.length,
                        isSvgMap,
                    }}>
                        <SidebarContext.Provider value={{
                            regionsOfMaps: null,
                            screenList,
                            onSubmit,
                            locationState,
                            hasMap,
                            openStreetMapName: '',
                            renderedMapName: '',
                        }}>
                            <ScreenNameContext.Provider value={{
                                screenName
                            }}>
                                <Sidebar title={'Index'}>
                                    <Box
                                        component="main"
                                        className="main-wrapper"
                                        sx={{ background: globalTheme[globalTheme.activeTheme]?.background }}
                                    >
                                        <Toolbar />
                                        <Container maxWidth="false">
                                            <div className="control-section" style={{
                                                background: globalTheme[globalTheme.activeTheme]?.background
                                            }}>
                                                <div className="control-section">
                                                    <ReactGridLayout
                                                        margin={[10, 10]}
                                                        rowHeight={screenType==='efabc6ad-1a62-4fa6-9c83-0427b693ea0f' ? dynamicGridHeight : fixedGridHeight}
                                                        layouts={layouts}
                                                        onResizeStop={calculateGridHeight}
                                                        onLayoutChange={(layout, layouts) =>
                                                            onLayoutChange(layout, layouts)
                                                        }
                                                        draggableHandle=".draggablePanelHeader"
                                                        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                                                        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                                                    >
                                                        {panelsList?.map((panel, _, arr) => (
                                                            <div
                                                                key={panel.sys_panels_guid}
                                                                id={panel.sys_panels_guid}
                                                                data-grid={{
                                                                    x: panel.panel_layout_json?.col,
                                                                    y: panel.panel_layout_json?.row,
                                                                    w: panel.panel_layout_json?.sizeX,
                                                                    h: panel.panel_layout_json?.sizeY > 12 ? panel.panel_layout_json?.sizeY / fixedGridHeight : panel.panel_layout_json?.sizeY,
                                                                    isResizable: isScreenEditable,
                                                                    isDraggable: isScreenEditable,
                                                                }}
                                                                className={`grid-item`}
                                                            >
                                                                <PanelsHeader draggableHandleClass="draggablePanelHeader" panel={panel} />
                                                                <PanelsContent
                                                                    panel={panel}
                                                                    screenList={screenList}
                                                                    d={sidebarScreenParameters}
                                                                    dynamicGridHeight={dynamicGridHeight}
                                                                />

                                                            </div>
                                                        )
                                                        )}
                                                    </ReactGridLayout>


                                                </div>
                                            </div>
                                        </Container>
                                    </Box>
                                </Sidebar>
                            </ScreenNameContext.Provider>
                        </SidebarContext.Provider>
                    </OptionsValueContext.Provider>
                </ActionButtonContextProvider>
            </SelectedRowContextProvider>
        </>
    )
}

export default DynamicScreen