import React, { useState, useEffect, useContext } from 'react'
import Sidebar from '../../../components/Sidebar'
import { Box, Container } from '@mui/material'
import GridLayout from "react-grid-layout";
import { UsersListEdit } from '.';
import { GlobalTheme } from '../../../context/GlobalTheme'


export const UsersEditGrid = () => {
    const globalTheme = useContext(GlobalTheme)

    return (
        <>
            <Sidebar>
                <Box component="tenants-grid" className="main-wrapper">
                    <Container maxWidth="false">
                        <div className="control-section"
                            style={{ marginTop: '50px', minHeight: '80vh' }}
                        >
                            <div className="control-section">
                                <GridLayout
                                    cols={12}
                                    rowHeight={68}
                                    width={1200}
                                >
                                    <div
                                        style={{ border: `1px solid ${globalTheme[globalTheme.activeTheme]?.headerBackground}` }}
                                        key="a"
                                        data-grid={{
                                            x: 0,
                                            y: 0,
                                            w: 12,
                                            h: 12,
                                            static: true
                                        }}
                                    >
                                        <UsersListEdit />
                                    </div>
                                </GridLayout>
                            </div>
                        </div>
                    </Container>

                </Box>
            </Sidebar>
        </>
    )
}