import React, {useEffect, useState} from "react";
import {Form} from "@patternfly/react-core";
import {FormControl, MenuItem, Select, InputLabel, Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useApolloClient} from "@apollo/react-hooks";

import {Link} from "react-router-dom";
import {getMap, getMaps, getMapsViewByMap} from "../../apollo/queries";
import loader from '../../../assets/loader.gif';
import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";

const SettingPanel = () => {
    const mapTypes = useSelector((state) => state.mainReducer.mapTypes);
    const mapsByType = useSelector((state) => state.mainReducer.maps);

    const currentMapType = useSelector((state) => state.mainReducer.currentMapType);
    const currentMap = useSelector((state) => state.mainReducer.currentMap);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const client = useApolloClient();
    const user = JSON.parse(localStorage.getItem('user'));
    const locale = localStorage.getItem('locale');

    let onMapTypeChange = (event) => {
        dispatch({type: 'SET_CURRENT_MAP_TYPE', data: {maps_types_guid: event, maps_types_default_display_name: ''}});
        dispatch({type: 'SET_CURRENT_MAP', data: {maps_guid: ''}});
        dispatch({type: 'SET_CURRENT_REGIONS_GROUP', data: ''});
        dispatch({type: 'SET_MAPS', data: []});

        _getMapsByType(event);
    }

    let onMapChange = (event) => {
        dispatch({type: 'SET_CURRENT_MAP', data: {maps_guid: event}});
        dispatch({type: 'SET_CURRENT_REGIONS_GROUP', data: ''});
        _getMap(event)
    }

    const _getMapsByType = async (maps_types_guid) => {
        try{
            setLoading(() => true);
            const {data, errors} = await client.query({query: getMaps, variables: {maps_types_guid, locale: locale ? locale : "en"}});
            setLoading(() => false);
            if(data) dispatch({type: 'SET_MAPS', data: data.dal_get_maps_list2});
            if(errors?.length > 0){
                throw new Error(errors[0].message)
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }

    const _getMap = async (maps_guid) => {
        try {
            const {data, errors} = await client.query({query: getMap, variables: {maps_guid, tenants_guid: user.tenant.tenants_guid, users_guid: user.user_uid, locale: locale ? locale : "en"}});
            if(data.dal_get_maps && data.dal_get_maps[0]?.gql_results)  {
                await _getMapView(maps_guid, data.dal_get_maps[0].gql_results);
            }
            if(errors?.length > 0){
                throw new Error(errors[0].message)
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }

    const _getMapView = async (maps_guid, mapData) => {
        try {
            const {data, errors} = await client.query({query: getMapsViewByMap, variables: {maps_guid, locale: locale ? locale : "en"}});

            const checkIsJSON = !!mapData.maps_real_size ? JSON.parse(mapData.maps_real_size.replaceAll('\\', '')) : ''

            if (data.dal_get_maps_views_list2.length > 0) {
                dispatch({
                    type: 'SET_CURRENT_MAP', data: {
                        ...mapData,
                        maps_view: {
                            maps_views_guid: data.dal_get_maps_views_list2[0].maps_views_guid,
                            maps_views_zoom: data.dal_get_maps_views_list2[0].maps_views_zoom,
                            maps_views_centroid: data.dal_get_maps_views_list2[0].maps_views_centroid
                        },
                        maps_real_size: checkIsJSON
                    }
                });
            } else {
                dispatch({
                    type: 'SET_CURRENT_MAP', data: {
                        ...mapData,
                        maps_view: { maps_views_guid: null, maps_views_zoom: null, maps_views_centroid: null },
                        maps_real_size: checkIsJSON
                    }
                });
            }
            if(errors?.length > 0){
                throw new Error(errors[0].message)
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        return () => {
            setLoading(() => false);
        };
    }, []);

    return (
        <div style={{display: 'flex', gap: '15px', marginLeft: '15px'}}>
            <section className='dynamic-forms'>
                <label style={{fontSize: '12px', color: '#757575'}}>Map type</label>
                <DropDownListComponent
                    dataSource={mapTypes}
                    fields={{value: 'maps_types_guid', text: 'maps_types_name'}}
                    select={({ itemData }) => {
                        onMapTypeChange(itemData.maps_types_guid);
                    }}
                    value={currentMapType.maps_types_guid}
                />
            </section>

            <section className='dynamic-forms'>
                <label style={{fontSize: '12px', color: '#757575'}}>Map</label>
                <DropDownListComponent
                    dataSource={mapsByType}
                    fields={{value: 'maps_guid', text: 'maps_name'}}
                    select={({ itemData }) => {
                        onMapChange(itemData.maps_guid);
                    }}
                    disabled={!currentMapType.maps_types_guid}
                    value={currentMap.maps_guid}
                />
            </section>
        </div>
    );
}

export default SettingPanel