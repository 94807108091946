import React, { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useForm, Controller } from "react-hook-form";
import { gql } from "@apollo/client";
import Input from './input'
import { getTaskStatus } from "../../apollo/queries";
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import { ActionButtonContext } from '../../context/ActionButtonContext';
import { SidebarContext } from './Main/context';
import { getLocaleDateString } from '../../utils/getLocaleDateString';


const Error = ({ children }) => <p style={{ color: "red" }}>{children}</p>;

const FormsQuery = ({ panel, formsQuery, queryName, entitiesId, l, animalsId }) => {
    const navigate = useNavigate();
    const client = useApolloClient();
    const locale = localStorage.getItem('locale');
    const { handleSubmit, control, setValue, formState: { errors }, getValues} = useForm();
    const toastRef = React.useRef(null);
    const { actionButton, changeActionButton } = useContext(ActionButtonContext);
    const sidebarContext = useContext(SidebarContext);
    const sortedControls = [...panel.panel_json.controls];
    sortedControls.sort((a, b) => a.column_order - b.column_order);
    const localDateFormat = getLocaleDateString()


    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const _getFormsQuery = async () => {
            try {
                const { data } = await client.query({ query: gql`${formsQuery}` })
                const responseData = data[queryName][0]
                const fields = []
                panel.panel_json.controls.map((c) => {
                    const { name } = c;
                    fields.push(name)
                })
                if (isMounted) {

                    fields.forEach(field => {
                        if (animalsId) {
                            setValue(`${animalsId.param.replace(/v_/g, "")}`, animalsId.param_value)
                        }
                        setValue(field, responseData?.[field])
                    })

                }
            } catch (err) {
                console.error(err);
            }
        }
        _getFormsQuery()

        return () => {
            isMounted = false;
            controller.abort();
        }

    }, [panel,sidebarContext?.onSubmit])


    const formInputs = sortedControls.map((c, index) => {
        const { required, label, name } = c;
        let { query } = c;
        if (query !== null) {
            query = query.replace('@v_locale', l).replace('\"49e2b2c9-1265-4ea4-bf86-3d9fb5f13aaf\"', entitiesId)
            query = query.substring(1, query.length - 1)
        }


        return (
            <section className='dynamic-forms' key={index}>
                <label style={{ fontSize: '12px', display: c.hidden ||  c.type === 'geometry' ? 'none' : 'block', color: '#757575' }}>{label}</label>
                <Controller
                    name={name}
                    control={control}
                    rules={{ required: required }}
                    defaultValue=""
                    render={({ field }) => (
                        <div style={{ display: c.hidden ? 'none' : 'block' }}>
                            <Input
                                value={field.value}
                                onChange={field.onChange}
                                inputQuery={query}
                                {...c}
                            />
                        </div>
                    )}
                />
                {errors[name] && <Error>{label} field is required</Error>}
            </section>
        );
    });

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    const onSubmit = async (formData) => {
        const formDataCopy = JSON.parse(JSON.stringify(formData, (key, value) => {
            if (typeof value === 'undefined') {
                return null;  // Replaces undefined values with null
            }
            return value;
        }));
        const currentFormat = localDateFormat.toUpperCase()
        for (const [key, value] of Object.entries(formDataCopy)) {
            if (moment(value, currentFormat, true).isValid()) {
                console.log(value)
                formDataCopy[key] = moment.utc(value, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ssZ");
            }
        }
        let JSONObject = actionButton.action_parameters
        delete JSONObject['action_order']
        let actionParameters = JSON.stringify(JSONObject)
        for (let key in formDataCopy) {
            if (formDataCopy[key] === "") {
                actionParameters = actionParameters.replace(`@${key}`, null)
            }
            else if(formDataCopy[key] !== null && formDataCopy[key].hasOwnProperty('coordinates')){
                actionParameters = actionParameters.replace(`@${key}`, `POINT(${formDataCopy[key]?.coordinates[0]} ${formDataCopy[key]?.coordinates[1]})`)
            }
            else {
                actionParameters = actionParameters.replace(`@${key}`, `${formDataCopy[key]}`)
            }

        }
        actionParameters = actionParameters.replace("@v_locale", "en")
        actionParameters = actionParameters.replace(/"([^"]+)"\s*:/g, '$1:')
        actionParameters = actionParameters.replace(/\"null\"/g, null)
        actionParameters = actionParameters.replace(/action_order:1,/g, '')
        const { data } = await client.query({
            query: gql`
                            query myQuery{
                                dal_add_task(args: {
                                    v_task_name: "${panel.panel_name}",
                                    v_task_description: "${panel.panel_name}",
                                    v_task_params: ${actionParameters},
                                    v_tasks_guid: ${null},
                                    v_task_priority: ${10},
                                    v_tasks_types_guid: "${actionButton.action_value}"}){
                                        gql_results
                                        return_code
                                        return_code_locale
                                    }
                            }`});
        async function showTaskStatus() {
            const status = await client.query({
                query: getTaskStatus,
                variables: { tasks_guid: data?.dal_add_task[0]?.gql_results.tasks_guid }
            });

            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0]?.return_code_locale.message, 'success');
                return setTimeout(() => { navigate(-1) }, 1000);
            } else {
                if (status?.data?.dal_get_task_status[0].return_code_locale || status?.data?.dal_get_task_status[0].gql_results.task_status === "FAILED") {
                    showToast(status?.data?.dal_get_task_status[0]?.return_code_locale?.message || 'Task failed', 'danger');
                } else {
                    showTaskStatus(); // Call the function recursively to run the code again
                }
            }
        }

        if (data?.dal_add_task[0]?.return_code_locale) {
            showTaskStatus(); 
        }
    };

    useEffect(() => {
        if (actionButton) {
            handleFormSubmit();
        }
        return () => {
            changeActionButton(null); // Clear the form errors when the component unmounts
        };
    }, [actionButton]);
    



    const handleFormSubmit = async () => {
        const isValid = await handleSubmit(onSubmit)();
        if (isValid) {
            // Form is valid, continue with form submission
            const formData = getValues();
            onSubmit(formData);
        } else {
            console.log(errors)
        }
    };

    return (
        <div className="wrapper" id="forms" style={{ padding: '24px 32px', background: '#fff', overflow: 'auto', height: 'calc(100% - 41px)' }}>
            <ToastComponent ref={toastRef} />
            <form onSubmit={handleFormSubmit}>
                {/* render the form inputs */}
                {formInputs}
            </form>
        </div>

    )


}

export default FormsQuery
