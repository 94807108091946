import styled from 'styled-components';
import ButtonMui from '@mui/material/Button';

export const Wrapper = styled('div')`
  margin-top: 24px;
  margin-left: 12px;
`

export const Button = styled(ButtonMui)`
    &:not(:last-child) {
      margin-right: 8px;
    }
`