import React from 'react';
import {Form, Text, TextVariants, Button, Slider, FormGroup} from '@patternfly/react-core';
import {TimesIcon} from '@patternfly/react-icons';
// import SynFromGroupTextInput from "./formGroupTextInput";
// import SynFormGroupSelectServerinput from "./formGroupSelectServerInput";
// import {getGeometriesTypes} from "../../apollo/queries";
import {HuePicker} from "react-color";
import moment from 'moment'
import TextField from "@mui/material/TextField";
import {useSelector} from "react-redux";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";


const steps = [
    { value: 0, label: '0%', isLabelHidden: true },
    { value: 10, label: '10%', isLabelHidden: true},
    { value: 20, label: '20%', isLabelHidden: true},
    { value: 30, label: '30%', isLabelHidden: true},
    { value: 40, label: '40%', isLabelHidden: true},
    { value: 50, label: '50%' , isLabelHidden: true},
    { value: 60, label: '60%' , isLabelHidden: true},
    { value: 70, label: '70%', isLabelHidden: true},
    { value: 80, label: '80%', isLabelHidden: true},
    { value: 90, label: '90%', isLabelHidden: true},
    { value: 100, label: '100%', isLabelHidden: true}
];


const SynGradietHeatmap = (props) => {
    const onAddGradient = () => {
        const newGradients = [...props.gradients, {max_value: 0, colour: 'blue'}]
        props.onChange(newGradients);
    }
    const onDeleteGradient = (indexGradient) => {
        let newGradients = [...props.gradients];
        newGradients.splice(indexGradient, 1);
        props.onChange(newGradients);
    }

    const onChangeColor = (indexGradient, value) => {
        let newGradients = [...props.gradients];
        newGradients[indexGradient].colour = value.hex;
        props.onChange(newGradients);
    }

    const onChangeMaxValue = (indexGradient, value) => {
        let newGradients = [...props.gradients];
        newGradients[indexGradient].max_value = value;
        props.onChange(newGradients);
    }

    return (
        <>
            <FormGroup label={"Gradients"} />
            <Button variant="secondary" onClick={onAddGradient}>Add New Gradient</Button>
            <div style={{display: "flex", flexDirection: "column", height: 220, overflowY: "auto", overflowX: "hidden"}}>
                {props.gradients.map((g, index)=> <div style={{border: "1px solid #eee", padding: "2px 10px 10px 10px", margin:"2px 0"}} key={index}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <Text component={TextVariants.h3}>Gradient value: {g.max_value <= 1 ? g.max_value :  g.max_value / 100}</Text>
                            {props.gradients.length > 1 && <Button variant="link" style={{paddingTop: "2px", paddingRight:0}} onClick={() => onDeleteGradient(index)}>
                                <TimesIcon/>
                            </Button>
                            }
                        </div>
                        <Slider
                            value={g.max_value <= 1 ? g.max_value * 100 : g.max_value}
                            onChange={(value) => onChangeMaxValue(index, value)}
                            customSteps={steps}
                        />
                        <div style={{display: "flex"}}>
                            <div style={{ width: 15, marginRight: 2, backgroundColor: g.colour}} />
                            <HuePicker
                                width={"100%"}
                                color={ g.colour }
                                onChangeComplete={(value) => onChangeColor(index, value)}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )

};

const SynCreatePolygonForm = (props) => {
    const updateName = (value) => props.setGeometryData((prev) => ({...prev, geofenced_geometries_name: value.target.value}));
    const updateGeometryCentroid = (value) => props.setGeometryData((prev) => ({...prev, geometry_centroid: value.target.value}));

    const setCellStep = (value) => props.setPolygonData((prev) => ({...prev, cell_size_m: value}));
    const setMaxValue = (value) => props.setPolygonData((prev) => ({...prev, max_value: value}));
    const setRadius = (value) => props.setPolygonData((prev) => ({...prev, radius: value}));
    const setLocalExtrema = (value) => props.setPolygonData((prev) => ({...prev, use_local_extrema: !value}))
    const onChangeGradient = (value) => props.setPolygonData((prev) => ({...prev, heatmap_gradients: value}))
    const updateDate = (event) => {
        props.updateDate(event.target.value)
    }
    //type id = 2 ("Heatmap Show Extra fields")
    const extraFields = (
        <>
            {/*<SynFromGroupTextInput*/}
            {/*    label="Cell step (m)"*/}
            {/*    value={props.formData.cell_size_m}*/}
            {/*    setValue={setCellStep}*/}
            {/*    disabled={props.upd}*/}
            {/*/>*/}
            {/* <SynFromGroupTextInput
        label="Max"
        value={props.formData.max_value}
        setValue={setMaxValue}
      /> */}
            {/*<SynFromGroupTextInput*/}
            {/*    label="Radius"*/}
            {/*    value={props.formData.radius}*/}
            {/*    setValue={setRadius}*/}
            {/*/>*/}
            {/*<SynGradietHeatmap*/}
            {/*    gradients={props.formData.heatmap_gradients || [{max_value: 0, colour:"blue"}]}*/}
            {/*    onChange={onChangeGradient}*/}
            {/*/>*/}
        </>
    );
    return (
        <Form style={{width: "100%", padding: "10px 15px"}}>

            <TextField fullWidth label="Name" variant="outlined" value={props.geometryData.geofenced_geometries_name} onChange={updateName} className="mb-3"/>





            {/*{ props.upd ? <div className="row">*/}
            {/*    <div className="col">*/}
            {/*        <label htmlFor="from">*/}
            {/*            From <br />*/}
            {/*            <input id="from" type="datetime-local" defaultValue={props.date} name="from"*/}
            {/*                   onChange={updateDate}/>*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*    <div className="col">*/}
            {/*        <label htmlFor="to">*/}
            {/*            To <br />*/}
            {/*            <input  id="to" type="datetime-local" defaultValue={moment(props.date).endOf('day').format('YYYY-MM-DDTHH:mm')} name="to" />*/}
            {/*        </label>*/}
            {/*    </div>*/}
            {/*    <div style={{marginTop: '20px'}}>*/}
            {/*        <Button key="save" variant="primary" onClick={() => props.updateSave(true)}>Update</Button>*/}
            {/*    </div>*/}
            {/*</div> : null }*/}


            {/*<SynFormGroupSelectServerinput*/}
            {/*    label="Type"*/}
            {/*    property="geometry_types"*/}
            {/*    optionLabelProperty="geometry_type_name"*/}
            {/*    optionValueProperty="geometry_type_id"*/}
            {/*    value={props.formData.typeId}*/}
            {/*    setValue={setTypeId}*/}
            {/*    query={getGeometriesTypes}*/}
            {/*    variables={{where: {geometry_type_id:{_neq: 1}}}}*/}
            {/*/>*/}
            {/*<label>Use local extrema <br />*/}
            {/*    <input type="checkbox" value={props.formData.use_local_extrema} checked={props.formData.use_local_extrema} onChange={() => setLocalExtrema(props.formData.use_local_extrema)}/>*/}
            {/*</label>*/}

            {extraFields}
        </Form>
    );

}

export default SynCreatePolygonForm;


// import React from 'react';
// import {Form, Text, TextVariants, Button, Slider, FormGroup} from '@patternfly/react-core';
// import {TimesIcon} from '@patternfly/react-icons';
// // import SynFromGroupTextInput from "./formGroupTextInput";
// // import SynFormGroupSelectServerinput from "./formGroupSelectServerInput";
// // import {getGeometriesTypes} from "../../apollo/queries";
// import {HuePicker} from "react-color";
// import moment from 'moment';
//
// import { ButtonComponent, CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
// import {SliderComponent, TextBoxComponent  } from "@syncfusion/ej2-react-inputs"
// import {DialogComponent} from "@syncfusion/ej2-react-popups";
//
// const steps = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
// //     [
// //   { value: 0, label: '0%', isLabelHidden: true },
// //   { value: 10, label: '10%', isLabelHidden: true},
// //   { value: 20, label: '20%', isLabelHidden: true},
// //   { value: 30, label: '30%', isLabelHidden: true},
// //   { value: 40, label: '40%', isLabelHidden: true},
// //   { value: 50, label: '50%' , isLabelHidden: true},
// //   { value: 60, label: '60%' , isLabelHidden: true},
// //   { value: 70, label: '70%', isLabelHidden: true},
// //   { value: 80, label: '80%', isLabelHidden: true},
// //   { value: 90, label: '90%', isLabelHidden: true},
// //   { value: 100, label: '100%', isLabelHidden: true}
// // ];
//
// const sliderTicks = {
//     placement: "After",
//     format: "%",
//     // largeStep: 10,
//     // smallStep: 10,
//     showSmallTicks: true
// };
//
// const SynGradientHeatmap = (props) => {
//
//   const onAddGradient = () => {
//       console.log('onAddGradient', props.gradients)
//     const newGradients = [...props.gradients, {max_value: 0, colour: 'blue'}]
//     props.onChange(newGradients);
//   }
//   const onDeleteGradient = (indexGradient) => {
//     let newGradients = [...props.gradients];
//     newGradients.splice(indexGradient, 1);
//     props.onChange(newGradients);
//   }
//
//   const onChangeColor = (indexGradient, value) => {
//       let newGradients = [...props.gradients];
//       newGradients[indexGradient].colour = value.hex;
//       props.onChange(newGradients);
//   }
//
//   const onChangeMaxValue = (indexGradient, value) => {
//       let newGradients = [...props.gradients];
//       newGradients[indexGradient].max_value = value;
//       props.onChange(newGradients);
//   }
//
//   return (
//       <div>
//           <div>
//               <h3>Gradients</h3>
//           </div>
//
//           <ButtonComponent click={onAddGradient} style={{marginBottom: '10px'}}>Add New Gradient</ButtonComponent>
//
//           <div style={{display: "flex", flexDirection: "column", height: 220, overflowY: "auto", overflowX: "hidden"}}>
//               {props.gradients.map((g, index)=> <div style={{border: "1px solid #eee", padding: "2px 10px 10px 10px", margin:"2px 0"}} key={index}>
//                       <div style={{display: "flex", justifyContent: "space-between"}}>
//                           <h3>Gradient value: {g.max_value <= 1 ? g.max_value :  g.max_value / 100}</h3>
//                           {props.gradients.length > 1 && <ButtonComponent cssClass='e-link' click={() => onDeleteGradient(index)}>
//                               <span id="close" className="e-icons e-date-time"></span>
//                           </ButtonComponent>
//
//                               // <button onClick={onAddGradient}>Add New Gradient</button>
//                               // <Button variant="link" style={{paddingTop: "2px", paddingRight:0}} onClick={() => onDeleteGradient(index)}>
//                           // </Button>
//                           }
//                       </div>
//
//                       <SliderComponent
//                           customValues={steps}
//                           value={g.max_value <= 1 ? g.max_value * 100 : g.max_value}
//                           onChange={(value) => onChangeMaxValue(index, value)}
//                           tooltip={ { isVisible: true, format: "00" }}
//                           ticks={sliderTicks}
//                           step={1}
//                       />
//
//                       <div style={{display: "flex", marginTop: '15px'}}>
//                           <div style={{ width: 15, marginRight: 2, backgroundColor: g.colour}} />
//                           <HuePicker
//                               width={"100%"}
//                               color={ g.colour }
//                               onChangeComplete={(value) => onChangeColor(index, value)}
//                           />
//                       </div>
//                   </div>
//               )}
//           </div>
//       </div>
//   )
//
// };
//
// const SynCreatePolygonForm = (props) => {
//     console.log('SynCreatePolygonForm ', props)
//
//   const setName = (value) => props.setFormData((prev) => ({...prev, name: value}));
//   const setTypeId = (value) => props.setFormData((prev) => ({...prev, typeId: 2}));
//   const setCellStep = (value) => props.setFormData((prev) => ({...prev, cell_size_m: value}));
//   const setMaxValue = (value) => props.setFormData((prev) => ({...prev, max_value: value}));
//   const setRadius = (value) => props.setFormData((prev) => ({...prev, radius: value}));
//   const setLocalExtrema = (value) => props.setFormData((prev) => ({...prev, use_local_extrema: !value}))
//   const onChangeGradient = (value) => props.setFormData((prev) => ({...prev, heatmap_gradients: value}))
//   const updateDate = (event) => {
//     props.updateDate(event.target.value)
//   }
//   //type id = 2 ("Heatmap Show Extra fields")
//
//   const extraFields = (
//     <div>
//         <TextBoxComponent style={{marginBottom: '10px'}} placeholder="Cell step (m)" floatLabelType="Auto" disabled={props.upd} value={props.formData.cell_size_m} onChange={ev => setCellStep(ev.target.value)}/>
//
//         {/*<input className="e-input" style={{marginBottom: '10px'}} disabled={props.upd}*/}
//         {/*       type="text" placeholder="Cell step (m)" value={props.formData.cell_size_m} onChange={ev => setCellStep(ev)}/>*/}
//
//         <TextBoxComponent style={{marginBottom: '10px'}} placeholder="Max" floatLabelType="Auto" value={props.formData.max_value} onChange={ev => setMaxValue(ev.target.value)}/>
//
//         <TextBoxComponent style={{marginBottom: '10px'}} placeholder="Radius" floatLabelType="Auto" value={props.formData.radius} onChange={ev => setRadius(ev.target.value)}/>
//
//       <SynGradientHeatmap
//         gradients={props.formData.heatmap_gradients || [{max_value: 0, colour:"blue"}]}
//         onChange={onChangeGradient}
//       />
//     </div>
//   );
//
//     // position={{X: 'center', Y: 'center' }}
//   return (
//       <div className="form-wrapper">
//           <button onClick={console.log}>Cancel</button>
//
//               {/*<DialogComponent id="dlg-button" showCloseIcon={true} closeOnEscape={false} isOpen={props.openModal} header="Polygon form"*/}
//               {/*                 style={{width: '200px', maxWidth: props.mapRef.current._size.x, maxHeight: props.mapRef.current._size.y, }} >*/}
//
//                   { props.upd ? <div className="row">
//                       <div className="col">
//                           <label htmlFor="from">From <br />
//                               <input id="from" type="datetime-local" defaultValue={props.date} name="from" onChange={updateDate}/>
//                           </label>
//                       </div>
//                       <div className="col">
//                           <label htmlFor="to">To <br />
//                               <input  id="to" type="datetime-local" defaultValue={moment(props.date).endOf('day').format('YYYY-MM-DDTHH:mm')} name="to" />
//                           </label>
//                       </div>
//                       <div style={{marginTop: '20px'}}>
//                           <ButtonComponent key="save" variant="primary" click={() => props.updateSave(true)}>Update</ButtonComponent>
//                       </div>
//                   </div> : null }
//
//                   <TextBoxComponent style={{marginBottom: '10px'}} placeholder="Name" floatLabelType="Auto" value={props.formData.name} onChange={ev => setName(ev.target.value)}/>
//
//                   <CheckBoxComponent style={{marginBottom: '10px'}} label="Use local extrema" value={props.formData.use_local_extrema} checked={props.formData.use_local_extrema} onChange={() => setLocalExtrema(props.formData.use_local_extrema)}/>
//
//                   {extraFields}
//
//                   <div style={{display: 'flex', justifyContent: 'center'}}>
//
//                       <ButtonComponent click={props.save} style={{marginRight: '10px'}}>Save</ButtonComponent>
//                       {/*<ButtonComponent onClick={props.cancel}>Cancel</ButtonComponent>*/}
//
//
//                   </div>
//
//               {/*</DialogComponent>*/}
//       </div>
//   );
// }
//
// export default SynCreatePolygonForm;
