import { createStore } from 'redux';
import { combineReducers } from 'redux';

import mainReducer from './reducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(combineReducers({mainReducer: mainReducer}));
const persister = 'Free';

export { store, persister };
