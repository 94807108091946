import gql from "graphql-tag";

export const getProjectViewList = gql `
    subscription getProjectViewList($project_uid: uuid) {
        dal_get_projects_views_list(args: {v_project: $project_uid}) {
            projects_guid
            projects_views_guid
            projects_views_name
            tenants_guid
            latitude
            longitude
            default_magnification
    }   
}`;

export const GET_ALERT_SUBSCRIPTION = gql`
    subscription MyQuery {
        alerts_alerts_latest {
            last_update
    }
}
`