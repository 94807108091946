import { useRef } from 'react';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { useNavigate, useParams } from 'react-router';
import { SelectedRowContext } from '../../../context/SelectedRowContext';
import { GlobalTheme } from '../../../context/GlobalTheme';
import { ActionButtonContext } from '../../../context/ActionButtonContext';
import { useContext } from 'react';


const PanelsContent = ({ panel, draggableHandleClass }) => {
    const toastRef = useRef(null);
    const navigate = useNavigate();
    const { selectedRow } = useContext(SelectedRowContext);
    const { changeActionButton, actionButton } = useContext(ActionButtonContext);
    const { id } = useParams()
    const globalTheme = useContext(GlobalTheme)

    const goToAddPage = (b) => {
        if (b?.action_value === "") {
            return "";
        }
        navigate(`/${b?.action_value}`, { state: { param_value: b } });
    }

    const goToEditPage = (b, selectedRow) => {
        if (selectedRow) {

            let parameterId = JSON.parse(JSON.stringify(b))
            let selectedId = parameterId.action_parameters[0].param_value.replace(/@/g, '')
            parameterId.action_parameters[0].param_value = selectedRow[selectedId]
            navigate(`/${b?.action_value}`, { state: { param_value: parameterId } })
        }

    }

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    const getActionButton = (args) => {
        if (args.item.action_name) {
            delete args.item.finalUpdate
            delete args.item.parentObj
            delete args.item.callChildDataBind
            delete args.item.controlParent
            if (args.item.action_parameters.length > 1) {
                changeActionButton(args.item)
            }
            else {
                switch (true) {
                    case args.item.action_name.toLowerCase().includes('add'): {
                        return goToAddPage(args.item)
                    }

                    case args.item.action_name.toLowerCase().includes('edit'):
                        if (selectedRow) {
                            return goToEditPage(args.item, selectedRow)
                        }
                        else {
                            return showToast('Please select a row which you want to edit!', 'danger')
                        }
                    default:
                        return null
                }
            }

        }
    }

    const goToEditReport = () => {
        if (id) {
            navigate(`/edit-report`, { state: { screenId: id } });
        }
    }

    const generateMenuItems = (buttons) => {
        if (!buttons) {
            return [];
        }

        return buttons
            .map((button) => {
                const menuItem = {
                    ...button,
                    text: button.action_name,
                    iconCss: `e-icons e-${button.icon_name}`,
                    cssClass: 'menu-component-button',
                    items: generateMenuItems(button.buttons)
                };

                return menuItem;
            });
    }

    const _createToolbarMenu = () => {
        const menuItems = generateMenuItems(panel?.panel_actions_json?.buttons);
        return (
            <div className={`${globalTheme?.activeTheme}`} style={{height: '40px'}}>
                <MenuComponent items={menuItems} select={getActionButton}></MenuComponent>
            </div>
        )
    }

    const _createReportToolbarMenu = () => {
        const menuItems = [{ text: 'Edit Report' }]
        return (
            <div className={`menu-container ${globalTheme?.activeTheme}`}>
                <MenuComponent items={menuItems} select={goToEditReport}></MenuComponent>
            </div>
        )
    }

    return (
        <>
            <ToastComponent ref={toastRef} />
            <div className={draggableHandleClass} style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: globalTheme[globalTheme.activeTheme]?.headerBackground, padding: '0 10px', alignItems: 'center', height: '40px', fontSize: '14px', }}>
                {panel && panel.panel_name}
                {
                    panel?.panel_actions_json?.buttons ? _createToolbarMenu() : panel?.panel_json?.element_type === 'report' ? _createReportToolbarMenu() : null
                }

            </div>
        </>

    )
}

export default PanelsContent