import * as actionTypes from './actions';
import { SET_REGIONS_TYPES_STATIC } from './actions';

export const initialState = {
    animatedPointsMode: 1, // 1-default, 2-animated
    showPointMode: 2, // 1-all points, 2-only final point

    animatedPointsModeStaticMap: 1,
    showPointModeStaticMap: 2,

    showRegionsPicker: false,

    showRegionsPickerStatic: false,

    visibleSensorTypes: {
        sensor: true,
        sensorAOA: true,
        sensorTRN: true,
        sensorR: true,
        sensorN: true,
        sensorGPS: true,
        sensorCalcGPS: true,
    },
    currentMapType: {maps_types_guid: ''},
    currentMap: {maps_guid: '', regionGroups: [], regions: []},
    currentRegionGroup: '',

    currentSelectedPolygon: '',
    polygons: [],
    polygonsLength: 0,
    mapTypes: [],
    maps: [],

    mapsViews: [],
    currentMapsView: {maps_views_guid: ''},

    regionsGroups: [],
    regionsTypes: [],
    regionsTypesSettings: [],
    regionsTypesStatic: [],
    regions: [],

    allocatedRegionGroups: null,
    unallocatedRegionGroups: null,

    unsavedRegionsLength: 0,

    dataSources: []
};

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EDIT_MODE:
            return { ...state, animatedPointsMode: action.data };

        case actionTypes.EDIT_SHOW_POINT_MODE:
            return { ...state, showPointMode: action.data };

        case actionTypes.EDIT_SENSOR_TYPE_VISIBILITY:
            return { ...state, visibleSensorTypes: action.data };

        case actionTypes.EDIT_REGIONS_PICKER_VISIBILITY:
            return { ...state, showRegionsPicker: !state.showRegionsPicker };
        // StaticMap
        case actionTypes.EDIT_MODE_STATIC_MAP:
            return { ...state, animatedPointsModeStaticMap: action.data };

        case actionTypes.EDIT_SHOW_POINT_MODE_STATIC_MAP:
            return { ...state, showPointModeStaticMap: action.data };

        case actionTypes.EDIT_SENSOR_TYPE_VISIBILITY_STATIC_MAP:
            return { ...state, visibleSensorTypes: action.data };

        case actionTypes.EDIT_REGIONS_PICKER_VISIBILITY_STATIC_MAP:
            return { ...state, showRegionsPickerStatic: !state.showRegionsPickerStatic };

        case actionTypes.SET_CURRENT_MAP_TYPE:
            return { ...state, currentMapType: action.data };

            case actionTypes.SET_CURRENT_MAP:
            return {...state, currentMap: action.data};

            case actionTypes.ADD_POLYGON:

                if(!state.polygons.find(el => el.id === action.data.id)) return {...state, polygons: [...state.polygons, action.data]};
                // else if(action.data._latlngs[0]) {
                //
                // }

                    return {...state};

            case actionTypes.DELETE_POLYGON:
            return {...state, polygons: state.polygons.filter(el => el.id !== action.id)};

                case actionTypes.SET_CURRENT_POLYGON:
            return {...state, currentSelectedPolygon: action.data};

            case actionTypes.SET_MAP_TYPES:
            return {...state, mapTypes: action.data};
            case actionTypes.SET_MAPS:
            return {...state, maps: action.data};
            case actionTypes.ADD_MAP:
            return {...state, maps: [...state.maps, action.data]};
            case actionTypes.EDIT_MAP:
            return {...state, maps: state.maps.map(el => el.maps_guid === action.data.maps_guid ? {...action.data} : el)};
            case actionTypes.DELETE_MAP:
            return {...state, maps: state.maps.filter(map => map.maps_guid !== action.data),};

            // case actionTypes.SET_GEOMETRY_TYPES:
            // return {...state, geometryTypes: action.data};

            case actionTypes.SET_DATA_SOURCES:
            return {...state, dataSources: action.data};

        case actionTypes.SET_REGIONS:
            return {...state, regions: action.data};

            case actionTypes.SET_MAPS_VIEWS:
            return {...state, mapsViews: action.data};
            case actionTypes.SET_CURRENT_MAPS_VIEW:
            return {...state, currentMapsView: action.data};
        case actionTypes.ADD_MAPS_VIEW:
            return {...state, mapsViews: [...state.mapsViews, action.data]};
        case actionTypes.EDIT_MAPS_VIEW:
            return {...state, mapsViews: state.mapsViews.map(el => el.maps_views_guid === action.data.maps_views_guid ? {...action.data} : el)};
        case actionTypes.DELETE_MAPS_VIEW:
            return {...state, mapsViews: state.mapsViews.filter(map => map.maps_views_guid !== action.data),
            };

        case actionTypes.SET_UNSAVED_REGIONS_LENGTH:
            return {...state, unsavedRegionsLength: action.data};

        case actionTypes.SET_REGIONS_GROUPS:
            return {...state, regionsGroups: action.data};
            case actionTypes.SET_CURRENT_REGIONS_GROUP:
            return {...state, currentRegionGroup: action.data};
            case actionTypes.SET_REGIONS_TYPES:
            return {...state, regionsTypes: action.data};
            case actionTypes.SET_REGIONS_TYPES_SETTINGS:
            return {...state, regionsTypesSettings: action.data};
            case actionTypes.SET_REGIONS_TYPES_STATIC:
            return {...state, regionsTypesStatic: action.data};

            case actionTypes.SET_ALLOCATED_REGIONS_GROUPS:
            return {...state, allocatedRegionGroups: action.data};
            case actionTypes.SET_UNALLOCATED_REGIONS_GROUPS:
            return {...state, unallocatedRegionGroups: action.data};

        default:
            return state;
    }
};

export default mainReducer;
