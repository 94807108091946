import React, { useRef, useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css'; // Import the styles for marker clustering


const New = ({ panel, controls, animalsData }) => {
    const mapRef = useRef(null);

    useEffect(() => {
        // Initialize the map
        const center = [panel.panel_json.map_definition.centroid.coordinates[1], panel.panel_json.map_definition.centroid.coordinates[0]] || [51.505, -0.09];
        const zoomLevel = panel.panel_json.map_definition.zoom || 13;
        const map = L.map(mapRef.current).setView(center, zoomLevel);

        // Add a tile layer to the map
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors',
        }).addTo(map);

        // Create a marker cluster group
        const markers = L.markerClusterGroup();

        // Add markers to the cluster group
        const controlsMapped = controls.map(({ hidden, name, label }) => ({ hidden, name, label }));
        const toMutateAnimalsData = (data) => {
            const mutationAnimalsData = [];

            const extractCoordinates = (item) => {
                const temp = {};

                for (const key in item) {
                    if (key === 'type' && item[key] === 'Point' && item.coordinates) {
                        temp.coordinates = item.coordinates;
                    } else if (typeof item[key] === 'object') {
                        const nestedCoordinates = extractCoordinates(item[key]);
                        if (nestedCoordinates) {
                            temp.coordinates = nestedCoordinates;
                        }
                    }
                }

                return temp.coordinates;
            };

            animalsData && animalsData.forEach((item = {}) => {
                const temp = {};
                const coordinates = extractCoordinates(item);

                if (coordinates) {
                    temp.coordinates = coordinates;
                    mutationAnimalsData.push(temp);
                }

                for (let i = 0; i < controlsMapped.length; i++) {
                    const animal = controlsMapped[i];
                    if (!animal.hidden) {
                        const label = animal.label;
                        temp[label] = item[animal.name];
                    }
                }
            });

            return mutationAnimalsData;
        };

        const mutationAnimalsData = toMutateAnimalsData();
        const getPopupContent = (animal) => {
            let popupContent = '';
            // Iterate over the properties of the animal object
            for (const [key, value] of Object.entries(animal)) {
                if (value) {
                    popupContent += `<b>${key}:</b> ${value}<br>`;
                }
            }

            return popupContent;
        };

        mutationAnimalsData.forEach((animal) => {
            const coordinates = animal.coordinates;

            if (coordinates) {
                const markerIcon = L.icon({
                    iconUrl: require('../../../../assets/map-pin-icon.svg'),
                    iconSize: [25, 41],
                    markerColor: 'yellow'
                });
                const marker = L.marker([coordinates[1], coordinates[0]], { icon: markerIcon }).bindPopup(getPopupContent(animal));
                markers.addLayer(marker);
            }
        });

        // Add the marker cluster group to the map
        map.addLayer(markers);

        // Clean up when the component is unmounted
        return () => {
            map.remove();
        };
    }, [panel, controls, animalsData]); // The empty dependency array ensures that this effect runs once after the initial render

    return <div ref={mapRef} style={{ height: '100%' }} />;
};

export default New;