export const SENSORS = {
    'sensorAOA': {color: '#0023af', title: 'AOA'},
    'sensorR': {color: '#9c02af', title: 'RSSI with noise'},
    'sensor': {color: '#02d22e', title: 'Base'},
    'sensorTRN': {color: '#ece11c', title: 'TRN'},
    'sensorN': {color: '#cb1717', title: 'RSSI'},
    'sensorGPS': {color: '#ec7200', title: 'GPS'},
    'sensorCalcGPS': {color: '#f810da', title: 'Calculated GPS'}
};

export const OPENSTREETMAP_TYPE_GUID = "f7ea79b0-bfdc-44c6-a8a0-8a9ff5243589";
export const RENDERED_MAP_TYPE_GUID = "a51868b4-04fe-4cd7-aefd-c14cc0672378";

