import React, { useRef } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  CheckBoxComponent,
  RadioButtonComponent,
  ButtonComponent,
} from "@syncfusion/ej2-react-buttons";


export const Input = ({ value, onChange, type, ...rest }) => {
  const inputRef = useRef(null);
  const fields = { text: "tenants_type_name", value: "tenants_types_guid" }
  switch (type) {
    case "text":
      return (
        <TextBoxComponent
          ref={inputRef}
          onClick={() => {
            inputRef.current?.element.focus()
          }}
          placeholder={rest?.placeholder}
          change={({ value }) => onChange(value)}
          value={value}
        />
      );
    case "radio":
      return rest?.options.map((e) => (
        <RadioButtonComponent
          key={e}
          label={e}
          value={e}
          onChange={(value) => onChange(value)}
          checked={value === e}
        />
      ));
    case "dropdown":
      return (
        <DropDownListComponent
          dataSource={rest?.options}
          fields={fields}
          select={({ itemData }) => {
            onChange(itemData[fields.value]);
          }}
          value={value}
        />
      );

    case "checkbox":
      return (
        <CheckBoxComponent
          label={rest?.checkboxLabel}
          onChange={(e) => onChange(e.target.checked)}
          checked={value}
        />
      );

    default:
      return null;
  }
};

export default Input;