import React, {useEffect, useMemo, useRef, useState} from 'react'
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import { ListItemTextSecondary, ListItem, PrimaryElement, TooltipElement, SecondaryTitle } from './styled'

export const AlertItem = ({ alertBody = '', alertLevel = '', alertTitle = '', alertValidFrom = '', channel = {} }) => {
    const splitedAlertValidFrom = alertValidFrom.split('T')
    const [time] = splitedAlertValidFrom[1].split('.')
    const ref = useRef(null)
    const [showTooltip, setShowTooltip] = useState(false)

    useEffect(() => {
        if (ref.current) {
            if (ref.current?.scrollWidth > ref.current?.clientWidth) {
                setShowTooltip(true)
            }
        }
    }, [ref])

    const tooltipItem = useMemo(() => (
        showTooltip ? (
            <TooltipElement>
                {alertBody}
            </TooltipElement>
        ) : null
    ), [showTooltip, alertBody])

    const primaryElement = useMemo(() => (
        <Tooltip title={tooltipItem} arrow>
            <PrimaryElement ref={ref}>
                {alertBody}
            </PrimaryElement>
        </Tooltip>
    ), [ref, tooltipItem, alertBody])

    const secondaryTitle = <div>{alertTitle}</div>

    return (
        <ListItem>
            <ListItemText primary={primaryElement} secondary={secondaryTitle} />
            <ListItemTextSecondary secondary={`${splitedAlertValidFrom[0]}, ${time}`} />
        </ListItem>
    )
}