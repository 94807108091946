import React, { memo, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { DashboardLayoutComponent, PanelDirective, PanelsDirective } from '@syncfusion/ej2-react-layouts'
import { toast, ToastContainer } from 'react-toastify'

import '../../../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css'
import '../components/map/style.css'
import SettingPanel from '../components/map/SettingPanel'
import RegionGroupsPanel from '../components/map/RegionGroupsPanel'
import ManageRegionsMapPanel from '../components/map/ManageRegionsMapPanel'
import SynModal from '../components/modals/modal'
import CreateRegionsGroupForm from '../components/forms/createRegionsGroupForm'
import { addRegionsGroups } from '../apollo/mutations'
import { getTaskStatus } from '../../apollo/queries'
import { useSelector } from 'react-redux'
import { OPENSTREETMAP_TYPE_GUID } from '../../utils/data'

const ManageRegions = memo(() => {
    const client = useApolloClient()
    const locale = localStorage.getItem('locale')

    const currentMap = useSelector((state) => state.mainReducer.currentMap)

    const [regionsGroupModalVisibility, setRegionsGroupModalVisibility] = useState(false)
    const [regionsGroupData, setRegionsGroupData] = useState({ regions_groups_guid: null, regions_groups_name: '' })

    const [canvasSize, setCanvasSize] = useState({ x: 0, y: 0 })

    const [settingPanelHeight, setSettingPanelHeight] = useState(null)

    const clearRegionsGroupData = () => {
        setRegionsGroupModalVisibility(false)
        setRegionsGroupData({ regions_groups_guid: null, regions_groups_name: '' })
    }

    const onRegionGroupSave = async () => {
        const { data } = await client.mutate({
            mutation: addRegionsGroups,
            variables: {
                regions_groups_guid: regionsGroupData.regions_groups_guid,
                regions_groups_name: regionsGroupData.regions_groups_name,
                maps_types_guid: currentMap.maps_types_guid || OPENSTREETMAP_TYPE_GUID,
                locale: locale ? locale : 'en'
            }
        })
        if (data?.insert_tasks_tasks?.returning) {
            const status = await client.query({
                query: getTaskStatus,
                variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid }
            })
            if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                clearRegionsGroupData()
                toast.success('Region group was created')
            } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                toast.error(`Something went wrong!`)
            }
        }
    }

    useEffect(() => {
        setSettingPanelHeight(15000 / window.innerWidth)
        setCanvasSize({ x: 98, y: 100 * (window.innerHeight - 190) / window.innerWidth })
        return () => {
            setSettingPanelHeight(() => null)
        }
    }, [currentMap])


    useEffect(() => {
        if (currentMap?.maps_img) {
            let size = { x: 0, y: 0 }
            let image = new Image()
            image.src = currentMap.maps_img
            image.onload = () => {
                size.x = 100 * image.width / (window.innerWidth - 260) + 2
                size.y = 100 * image.height / (window.innerWidth - 260) + 5
                setCanvasSize({ ...size })

                // if (clinicImage && clinicImage.width) {
                //     size.x = colNumber * clinicImage.width / window.innerWidth + 2;
                // }

                // if (clinicImage && clinicImage.height) {
                //     let panelHeight = ((colNumber * (window.innerHeight - 68) / window.innerWidth) * window.innerWidth) / colNumber - 50;
                //     if(clinicImage.height > panelHeight) {
                //         size.y = colNumber * clinicImage.height / window.innerWidth + 4;
                //         // size.y = colNumber * (window.innerHeight - 68) / window.innerWidth + ((clinicImage.height - panelHeight) * colNumber / window.innerHeight);
                //     } else {
                //         size.y = colNumber * (window.innerHeight - 68) / window.innerWidth;
                //     }
                // }

            }

        }
    }, [currentMap])

    return (
        <>
            {!!canvasSize.x && !!canvasSize.y &&
                <div style={{ height: '100%' }}>
                    <div className="control-section">
                        <DashboardLayoutComponent style={{ marginTop: 10 }} cellSpacing={[12, 15]} columns={100} allowResizing={false}
                                                  allowDragging={false}>
                            <PanelsDirective>
                                <PanelDirective sizeX={canvasSize.x} sizeY={canvasSize.y}
                                                content={() => ManageRegionsMapPanel()} header="Map">
                                </PanelDirective>
                                <PanelDirective sizeX={canvasSize.x > 100 ? canvasSize.x : 99}
                                                sizeY={settingPanelHeight}
                                                content={() => RegionGroupsPanel(setRegionsGroupModalVisibility)}
                                                header="Region groups">
                                </PanelDirective>
                                <PanelDirective sizeX={canvasSize.x > 100 ? canvasSize.x : 99}
                                                sizeY={settingPanelHeight} content={() => SettingPanel()}
                                                header="Settings">
                                </PanelDirective>
                            </PanelsDirective>
                        </DashboardLayoutComponent>
                    </div>

                    <SynModal
                        title="Region group"
                        openModal={regionsGroupModalVisibility}
                        setOpenModal={setRegionsGroupModalVisibility}
                        save={onRegionGroupSave}
                        cancel={clearRegionsGroupData}
                        content={<CreateRegionsGroupForm formData={regionsGroupData}
                                                         setFormData={setRegionsGroupData}/>}
                    />
                    <ToastContainer/>
                </div>
            }
        </>
    )
})

export default ManageRegions
